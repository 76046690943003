(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("TaskLogDialogController", TaskLogDialogController);

    TaskLogDialogController.$inject = ['section', 'task', 'UserAccount', 'TaskLog', '$timeout', '$state', 'AlertService', '$scope', 'taskLog', 'pagingParams', 'TrMaterial', 'ParseLinks', 'TrResource', 'TaskPathById', '$window', 'str', '$rootScope', 'Inventory', 'AllLogTypes','RouteByID'
    ];

    function TaskLogDialogController(section, task, UserAccount, TaskLog, $timeout, $state, AlertService, $scope, taskLog, pagingParams, TrMaterial, ParseLinks, TrResource, TaskPathById, $window, str, $rootScope, Inventory, AllLogTypes,RouteByID
    ) {
        var vm = this;
        vm.taskLogs = taskLog;
        var entity_old = angular.copy(vm.taskLogs);
        vm.taskLogs.taskLogType = str
        if (vm.taskLogs.taskLogType == 'PROGRESS') {
            vm.labelLog = "Daily Task Log"
        } else if (vm.taskLogs.taskLogType == 'OBSTACLE') {
            vm.labelLog = "Obstacle Log"
        }
        var coordinateList = []
        var chainageList = []
        var markerList = []
        var iconSize = 20
        var markersSection = []
        var markerstasks = []
        vm.logType = taskLog.logType;

        vm.selectAttributes = selectedLogTypeAttributes;

        var infowindow = new google.maps.InfoWindow();
        var openedInfoWindow = null;
        var btnAddSection;
        //vm.routeName = route
        /* vm.isEnableStep = false
        if(entity && entity.section){
            vm.isEnableStep = true
        } */

        vm.transactionType = ["PLANNED", "REQUESTED", "RECEIVED", "UTILIZED", "RECONCILED"]
        var today = new Date();
        today.setHours(23, 59, 59);
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.options = {
            maxDate: today
        };


        /*  var todayFormatted = today.toISOString().split("T")[0];
         document.getElementById("dateInput").max = todayFormatted; */

        var locations = {};
        var markers = [];
        var center = {
            lat: parseFloat($rootScope.DEFAULT_LATITUDE),
            lng: parseFloat($rootScope.DEFAULT_LONGITUDE),
        };
        locations.map = new google.maps.Map(
            document.getElementById("map_canvasStart"),
            myOption($rootScope.rolloutMapInitZoom, center)
        );
        geoLocation(locations.map);

        google.maps.event.addListener(locations.map, "click", function (event) {
            if (markers.length < 2) {
                // Add a marker to the map
                addMarker(event.latLng);
            } else {
                console.log("Maximum 2 markers allowed.");
            }
        });

        $scope.tab = 1;
        $scope.isSet = function (tabNum) {
            return $scope.tab === tabNum;
        };

        $scope.setTab = function (newTab) {
        }

        UserAccount.get(function (data) {
            vm.taskLogs.userId = data.id;
        });

        AllLogTypes.getAll(function (response) {
            vm.logTypes = response;
        });

        if (vm.taskLogs.id) {
            vm.taskLogs.task = { id: vm.taskLogs.task.id };

        } else if (!vm.taskLogs.id) {
            vm.taskLogs.task = { id: parseInt(task.id) };

        }

        if (vm.taskLogs.id) {
            vm.taskLogs.workDate = new Date(vm.taskLogs.workDate);
        }

        var route = null;

        if($state.params.routeId){
            RouteByID.get($state.params.routeId, function (response){
                route = response

                if(route && route.assets.length){
                    route.assets.forEach(function (element){
                        locations.map.setCenter({lat:element.assetCoordinates[0].latitude, lng:
                            element.assetCoordinates[0].longitude} );
                            
                         
                                
                                if(element.assetCoordinates.length){
        
                                    if(element.assetType.layout == "SPREAD"){
                                        vm.tempPath = []
                                    element.assetCoordinates.forEach(function (item){
                                        vm.tempPath.push({lat:item.latitude,lng:item.longitude})
                                    })
                                    var polyline = new google.maps.Polyline({
                                        path: vm.tempPath,
                                        geodesic: true,
                                        strokeColor: element.assetType.colorcode,
                                        strokeOpacity: 0.6,
                                        strokeWeight: 4,
                                    });
                                    //addClick(polyline);
                                    // Set the map to display the polyline
                                    polyline.setMap(locations.map);
                                    }
        
                                    if(element.assetType.layout == "FIXED"){
                                        element.assetCoordinates.forEach(function (e) {
                                            var marker = new google.maps.Marker({
                                            position:  {lat:e.latitude,lng:e.longitude},
                                            map: locations.map,
                                            icon: {
                                                scaledSize: new google.maps.Size(iconSize, iconSize),
                                                url: element.assetType.imagePath,
                                                anchor: new google.maps.Point(
                                                    iconSize / 2,
                                                    iconSize / 2
                                                ),
                                                labelOrigin: new google.maps.Point(25, 22),
                                            },
                                        });
                                       
                        
                                        })
                                      }
                                    }
                                    
                                })
                       
                }
            },function(err){
            })
        }

        function epoch(date) {
            return Date.parse(date)
        }

        function selectedLogTypeAttributes() {
            vm.showFilter = true;
            if (vm.logType) {
                vm.taskLogs.logType = vm.logType;
                if (
                    entity_old.logType &&
                    vm.logType.id === entity_old.logType.id
                ) {
                    vm.taskLogs.logTypeAttributeValues = [];
                    vm.taskLogs.logTypeAttributeValues =
                        entity_old.logTypeAttributeValues;
                } else {
                    vm.taskLogs.logTypeAttributeValues = [];
                    $.each(
                        vm.logType.logTypeAttribute,
                        function (key, value) {
                            vm.taskLogs.logTypeAttributeValues.push({
                                logTypeAttribute:
                                    vm.logType.logTypeAttribute[key],
                            });
                        }
                    );
                }
            }
        }

        vm.save = function () {
            vm.taskLogs.approvedByUserId = parseInt(vm.taskLogs.approvedByUserId)
            vm.taskLogs.workDate = epoch(vm.taskLogs.workDate)

            if (markers.length == 2) {
                markers.forEach(function (item) {
                    if (item.label.text == "from") {
                        if (vm.taskLogs.id) {
                            vm.taskLogs.fromRoutePoint.latitude = item.position.lat()
                            vm.taskLogs.fromRoutePoint.longitude = item.position.lng()
                        } else {
                            vm.taskLogs.fromRoutePoint = {
                                "latitude": item.position.lat(), "longitude": item.position.lng()
                            };
                        }

                    } else if (item.label.text == "to") {
                        if (vm.taskLogs.id) {
                            vm.taskLogs.toRoutePoint.latitude = item.position.lat()
                            vm.taskLogs.toRoutePoint.longitude = item.position.lng()
                        } else {
                            vm.taskLogs.toRoutePoint = {
                                "latitude": item.position.lat(), "longitude": item.position.lng()
                            };
                        }


                    }
                })
                callTaskLog()
            } else {
                alert("Points are Empty");
            }


            /*
                        TaskLog.postResorceTxn(
                            vm.taskLogsResource,
                            function () {
                            },
                            function () {}
                        ); */



        };


        $scope.toggle = function (id) {
            switch (id) {

                case "material":
                    vm.showMaterial = !vm.showMaterial;


                    break;

                case "resource":
                    vm.showResource = !vm.showResource;


                    break;

                default:
                    console.log("");
            }
        };

        TaskPathById.get(vm.taskLogs.task.id, function (response) {
            if (response.COORDINATE != null) {
                response.COORDINATE.forEach(function (coor) {
                    coordinateList.push({ lat: coor.latitude, lng: coor.longitude });
                });
            }

            if (response.CHAINAGE != null) {
                response.CHAINAGE.forEach(function (chainageVal) {
                    chainageList.push({ lat: chainageVal.latitude, lng: chainageVal.longitude, type: chainageVal.routePointType, number: chainageVal.routePointNumber });
                });
            }

            /*  if(response.MARKER != null){
                 response.MARKER.forEach(function (markerVal) {
                     markerList.push({ lat: markerVal.latitude, lng: markerVal.longitude });
                 });
             } */

            if (coordinateList.length) {
                locations.map.setCenter(coordinateList[0]);

                var polyline = new google.maps.Polyline({
                    path: coordinateList,
                    geodesic: true,
                    strokeColor: '#FD7A24',
                    strokeOpacity: 1.0,
                    strokeWeight: 4,
                    zIndex: 1
                });

                // Set the map to display the polyline
                polyline.setMap(locations.map);
                addClick(polyline)
            }

            /* if(markerList.length){
                locations.map.setCenter(markerList[0]);
                createMarkers(markerList,'content/images/markersPoint.png',locations.map)
            } */

            if (chainageList.length) {

                locations.map.setCenter(chainageList[0]);
                createMarkers(chainageList, 'content/images/chainagePoints.png', locations.map)
            }
        });


        //Functions

        function addClick(item) {
            // Add a click event listener to the map
            item.addListener("click", function (event) {
                if (markers.length < 2) {
                    // Add a marker to the map
                    addMarker(event.latLng);

                    // Get the latitude and longitude of the clicked point
                    vm.pinLat = event.latLng.lat();
                    vm.pinLong = event.latLng.lng();
                } else {
                    console.log("Maximum 2 markers allowed.");
                }
            });
        }


        function createMarkers(arr, img, map) {
            arr.forEach(function (each) {
                var marker = new google.maps.Marker({
                    position: { lat: each.lat, lng: each.lng },
                    map: map,
                    icon: {
                        scaledSize: new google.maps.Size(iconSize, iconSize),
                        url: img,
                        anchor: new google.maps.Point(
                            iconSize / 2,
                            iconSize / 2
                        ),
                        labelOrigin: new google.maps.Point(25, 22),
                    },
                    zIndex: 4
                });

                google.maps.event.addListener(
                    marker,
                    "click",
                    (function () {
                        //var name = (each.name == (undefined || null)) ? " " : each.name
                        if (openedInfoWindow != null) {
                            openedInfoWindow.close();
                        }
                        var userContent = document.createElement("div")

                        userContent.innerHTML = '<div class="infoWindowhead"></br></br>' +
                            /* "<b>Name : "+name+"</b></br>"+ */
                            "<b>Latitude : " + each.lat + "</b></br>" +
                            "<b>Longitude : " + each.lng + "</b></br>" +
                            "<b>Type : " + each.type + "</b></br>" +
                            "<b>Number : " + each.number + "</b></br>" +
                            "</br></div>"
                        infowindow.setContent(
                            userContent
                        );
                        infowindow.open(map);
                        infowindow.setPosition({
                            lat: each.lat,
                            lng: each.lng,
                        });
                        openedInfoWindow = infowindow;

                        if (markers.length < 2) {
                            btnAddSection = userContent.appendChild(
                                document.createElement("input")
                            );
                            btnAddSection.type = "button";

                            btnAddSection.value = "Add Log Point";

                            btnAddSection.className = "button";

                            btnAddSection.addEventListener("click", function () {
                                if (markers.length < 2) {
                                    // Add a marker to the map
                                    addMarker({
                                        lat: each.lat,
                                        lng: each.lng,
                                    });

                                    // Get the latitude and longitude of the clicked point
                                    vm.pinLat = each.lat;
                                    vm.pinLong = each.lng;
                                    openedInfoWindow.close();
                                } else {
                                    alert("From and To Log Exists");
                                }
                            })
                        }
                    }))


                //addClick(marker)
                arr.push(marker);
            })
        }

        function callTaskLog() {
            vm.taskLogs.sectionId = $state.params.sectionId;
            if (vm.taskLogs.id) {
                TaskLog.update(vm.taskLogs, onSuccessLog, function (error) { AlertService.error(error.data.message); })
            } else {
                TaskLog.post(
                    vm.taskLogs,
                    onSuccessLog,
                    function (error) {
                        AlertService.error(error.data.message);
                    }
                );
            }

        }

        function onSuccessLog() {
            if (vm.showMaterial) {
                vm.materialTxn.materialId = parseInt(vm.materialTxn.material)

                if (vm.materialTxn.transactionDate) {
                    vm.materialTxn.transactionDate = vm.materialTxn.transactionDate.getTime();
                }
                Inventory.postBulkMaterialTxn(
                    vm.materialTxn,
                    function () {
                        $window.history.back();
                    },
                    function (error) {
                        AlertService.error('Error - Material Transaction');
                    }
                );
            }

            if (vm.showResource) {
                vm.resourceTxn.resourceId = parseInt(vm.resourceTxn.resource)

                if (vm.resourceTxn.transactionDate) {
                    vm.resourceTxn.transactionDate = vm.resourceTxn.transactionDate.getTime();
                }

                Inventory.postBulkResourceTxn(vm.resourceTxn, function () {
                    $window.history.back();
                },
                    function (error) {
                        AlertService.error('Error - Resource Transaction');
                    })
            }

            if (!vm.showResource && !vm.showMaterial) {
                $window.history.back();
            }
        }


        vm.removeMarker = function () {
            markers.forEach(function (marker) {
                marker.setMap(null);
            });

            // Clear the markers array
            markers = [];
        }

        function addMarker(location) {
            var markerText
            if (markers.length == 0) {
                markerText = "from"
            } else if (markers.length == 1) {
                markerText = "to"
            }
            // Create a marker
            var marker = new google.maps.Marker({
                position: location,
                map: locations.map,
                label: {
                    text: markerText,
                },
                draggable: true
            });

            // Add the marker to the markers array
            markers.push(marker);
        }

        function mapStyles() {
            var styles;
            return (styles = [
                {
                    featureType: "poi",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    elementType: "labels.text.fill",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                } /* 
                {
                    elementType: "geometry.fill",
                    stylers: [
                        {
                            color: "#EFEFEF",
                        },
                    ],
                }, */,
                {
                    featureType: "transit",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    featureType: "road",
                    elementType: "labels.icon",
                    stylers: [{ visibility: "off" }],
                },
                {
                    featureType: "landscape",
                    stylers: [
                        {
                            color: "#efefef",
                        },
                    ],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry",
                    stylers: [
                        {
                            color: "#ffffff",
                        },
                    ],
                },
            ]);
        }

        function myOption(zoom, center) {
            var myOptions;
            return (myOptions = {
                zoom: zoom,
                center: center,
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: mapStyles(),
            });
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }



        if (vm.taskLogs.id && vm.taskLogs.fromRoutePoint && vm.taskLogs.toRoutePoint) {
            showMarker({ lat: vm.taskLogs.fromRoutePoint.latitude, lng: vm.taskLogs.fromRoutePoint.longitude }, "from")
            showMarker({ lat: vm.taskLogs.toRoutePoint.latitude, lng: vm.taskLogs.toRoutePoint.longitude }, "to")
            locations.map.setCenter({ lat: vm.taskLogs.fromRoutePoint.latitude, lng: vm.taskLogs.fromRoutePoint.longitude });
        }

        function showMarker(location, text) {
            var marker = new google.maps.Marker({
                position: location,
                map: locations.map,
                label: {
                    text: text,
                }
            });

            // Add the marker to the markers array
            markers.push(marker);
        }

        // if (section && section.id && section.fromRoutePoint && section.toRoutePoint) {
        //     addMarkerSection({ lat: section.fromRoutePoint.latitude, lng: section.fromRoutePoint.longitude }, "From Section")
        //     addMarkerSection({ lat: section.toRoutePoint.latitude, lng: section.toRoutePoint.longitude }, "To Section")
        //     locations.map.setCenter({ lat: section.fromRoutePoint.latitude, lng: section.fromRoutePoint.longitude });
        // }

        if (task && task.id && task.fromRoutePoint && task.toRoutePoint) {
            addMarkerTask({ lat: task.fromRoutePoint.latitude, lng: task.fromRoutePoint.longitude }, "From Task")
            addMarkerTask({ lat: task.toRoutePoint.latitude, lng: task.toRoutePoint.longitude }, "To Task")
            locations.map.setCenter({ lat: task.fromRoutePoint.latitude, lng: task.fromRoutePoint.longitude });
        }

        function addMarkerSection(location, text) {
            // Create a marker
            var marker = new google.maps.Marker({
                position: location,
                map: locations.map,
                icon: {
                    scaledSize: new google.maps.Size(iconSize, iconSize),
                    url: "content/images/sections.png",
                    anchor: new google.maps.Point(
                        iconSize / 2,
                        -4
                    ),
                    labelOrigin: new google.maps.Point(25, 22),
                },
                label: {
                    text: text,
                }
            });

            // Add the marker to the markers array
            markersSection.push(marker);
        }

        function addMarkerTask(location, text) {
            // Create a marker
            var marker = new google.maps.Marker({
                position: location,
                map: locations.map,
                icon: {
                    scaledSize: new google.maps.Size(iconSize, iconSize),
                    url: "content/images/tasks.png",
                    anchor: new google.maps.Point(
                        iconSize / 2,
                        iconSize / 2
                    ),
                    labelOrigin: new google.maps.Point(25, 22),
                },
                label: {
                    text: text,
                }


            });
            addClick(marker)

            // Add the marker to the markers array
            markerstasks.push(marker);
        }

        vm.objectRouteImg = {}
        vm.objectRouteImg.routeId = $state.params.routeId
        vm.objectRouteImg.map = locations.map



        //Material/Resource TXN
        vm.materialTxn = {}
        vm.resourceTxn = {}
        vm.isShowTXN = false

        vm.transactionType = ["PLANNED", "REQUESTED", "RECEIVED", "UTILIZED", "RECONCILED"]
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;

        if (section && section.id) {
            vm.materialTxn = [{ sectionId: parseInt($state.params.sectionId) }]
            vm.resourceTxn = [{ sectionId: parseInt($state.params.sectionId) }]
            vm.isShowTXN = true



            TrMaterial.query(
                {
                    page: 0,
                    size: 200,
                    sort: 'last_modified_date,desc',
                },
                onSuccess,
                onError
            );



            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers("link"));
                vm.totalItems = headers("X-Total-Count");
                vm.queryCount = vm.totalItems;
                vm.materials = [];
                data.forEach(function (value) {
                    if (value.zone) {
                        value.type = "MASTER";
                    } else {
                        value.type = "MINI";
                    }
                    vm.materials.push(value);
                });
                vm.page = pagingParams.page;
            }

            TrResource.query(
                {
                    page: 0,
                    size: 200,
                    sort: 'last_modified_date,desc',
                },
                onSuccessResource,
                onError
            );


            function onSuccessResource(data, headers) {
                vm.links = ParseLinks.parse(headers("link"));
                vm.totalItems = headers("X-Total-Count");
                vm.queryCount = vm.totalItems;
                vm.resources = [];
                data.forEach(function (value) {
                    if (value.zone) {
                        value.type = "MASTER";
                    } else {
                        value.type = "MINI";
                    }
                    vm.resources.push(value);
                });
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        $scope.addDetailsMaterial = function () {
            vm.materialTxn.push({ sectionId: $state.params.sectionId })
        }

        vm.dateChangedMaterial = function (index) {
            if (vm.materialTxn[index].transactionDate) {
                vm.materialTxn[index].transactionDate = epoch(vm.materialTxn[index].transactionDate)
            }
        }

        $scope.removeDetailsMaterial = function (id) {
            vm.materialTxn.splice(id, 1);
        };

        $scope.addDetailsResource = function () {
            vm.resourceTxn.push({ sectionId: $state.params.sectionId })
        }

        vm.dateChangedResource = function (index) {
            if (vm.resourceTxn[index].transactionDate) {
                vm.resourceTxn[index].transactionDate = epoch(vm.resourceTxn[index].transactionDate)
            }
        }

        $scope.removeDetailsResource = function (id) {
            vm.resourceTxn.splice(id, 1);
        };

        $('.select2').select2();

        vm.onChangeUom = function(id,index){
            
            var temp  = vm.materials.find(function (eachElement){
                return eachElement.id == id
            })

            vm.materialTxn[index].unitOfMeasurement = temp.unitOfMeasurement
        }

        function geoLocation(map) {
            var goTo = document.createElement("span");
            goTo.setAttribute("title", "Location Coordinates");
            goTo.classList.add("custom-map-control-button");
            var gotoIcon = document.createElement("i");
            gotoIcon.classList.add("fa");
            gotoIcon.classList.add("fa-location-arrow");
            gotoIcon.classList.add("geo");
            gotoIcon.classList.add("mt-5");
            goTo.appendChild(gotoIcon);
            map.controls[google.maps.ControlPosition.RIGHT_TOP].push(goTo);
            goTo.addEventListener("click", function () {
                vm.removeMarker()
                $("#modalLatLongs").show();
            });
        }

         vm.closeModal = function(id) {
            vm.getLocationCoordinateA = null
            vm.getLocationCoordinateB = null

            $("#" + id).removeClass("in");
            $(".modal-backdrop").remove();
            $("#" + id).hide();
        }

         vm.onLocationCoordinates = function() {
                var latlngA = vm.getLocationCoordinateA.split(",");
                var latlngB = vm.getLocationCoordinateB.split(",");

                addMarker({lat: parseFloat(latlngA[0]),lng: parseFloat(latlngA[1])})
                addMarker({lat: parseFloat(latlngB[0]),lng: parseFloat(latlngB[1])})

                locations.map.setCenter({lat: parseFloat(latlngB[0]),lng: parseFloat(latlngB[1])})
                vm.closeModal('modalLatLongs')
        }
    }
})();
