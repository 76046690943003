(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("AssetRouteController", AssetRouteController);

    AssetRouteController.$inject = ["$scope", "$rootScope", "TrProjectListIdName", "TrRouteListByProjectId", "AssetViewES", "AssetDemographicList"];

    function AssetRouteController($scope, $rootScope, TrProjectListIdName, TrRouteListByProjectId, AssetViewES, AssetDemographicList) {
        var vm = this;
        var locations = {};

        vm.projectList = [];
        vm.routeList = [];
        vm.selectedProjectId = 0;
        vm.selectedRouteIds = 0;

        vm.selectedStates = null;
        vm.selectedDistrict = null;
        vm.selectedBlock = null;

        vm.projectSelectBtn = projectSelectBtn;
        vm.routeSelectBtn = routeSelectBtn;

        vm.fixedAssets = [];
        vm.spreadAssets = [];

        var iconSize = 18;
        var infowindow = new google.maps.InfoWindow();

        var markers = [];
        var polylines = [];
        vm.distractChartVisible = false;
        vm.countChartVisible = false;

        init();


        function init() {
            mapHeigh();
            initMap();

            TrProjectListIdName.get(function (response) {
                vm.projectList = response;
            });
        }

        function initMap() {
            var center = {
                lat: parseFloat($rootScope.DEFAULT_LATITUDE),
                lng: parseFloat($rootScope.DEFAULT_LONGITUDE),
            };
            locations.map = new google.maps.Map(
                document.getElementById("map_view"),
                myOption(8, center)
            );
        }


        function mapHeigh() {
            var screenHeight = screen.height;
            if (screenHeight < 900) {
                document.getElementById("map_view").style.height = "65vh";
            } else {
                document.getElementById("map_view").style.height = "70vh";
            }
        }

        function projectSelectBtn() {
            vm.selectedRouteIds = [];
            vm.stateList = [];
            vm.districtList = [];
            vm.blockList = [];
            vm.distractChartVisible = false;
            vm.countChartVisible = false;
            TrRouteListByProjectId.get(vm.selectedProjectId[0].id, function (response) {
                vm.routeList = response;
            });
        }

        function routeSelectBtn() {
            vm.stateList = [];
            vm.districtList = [];
            vm.blockList = [];
            vm.fixedAssets = [];
            vm.spreadAssets = [];

            var routeIds = [];
            vm.selectedRouteIds.forEach(function (route) {
                routeIds.push(route.id);
            });
            vm.fixedAssets = [];
            vm.spreadAssets = [];
            callAssetsRouteByID(null, null, null, routeIds, null);
        }

        vm.onStateChange = function () {
            var routeIds = [];
            vm.selectedRouteIds.forEach(function (route) {
                routeIds.push(route.id);
            });
            vm.fixedAssets = [];
            vm.spreadAssets = [];
            callAssetsRouteByID(vm.selectedStates[0].name, null, null, routeIds, null);
            AssetDemographicList.getList({
                state: vm.selectedStates[0].name,
                district: null,
                block: null,
                routeIds: routeIds,
                lastAssetId: null
            }, function (data) {
                vm.districtList = [];
                data.forEach(function (district) {
                    vm.districtList.push({ name: district, ticked: false });
                });
            });
        }

        vm.onDistrictChange = function () {
            var routeIds = [];
            vm.selectedRouteIds.forEach(function (route) {
                routeIds.push(route.id);
            });
            vm.fixedAssets = [];
            vm.spreadAssets = [];
            callAssetsRouteByID(vm.selectedStates[0].name, vm.selectedDistrict[0].name, null, routeIds, null);
            AssetDemographicList.getList({
                state: vm.selectedStates[0].name,
                district: vm.selectedDistrict[0].name,
                block: null,
                routeIds: routeIds,
                lastAssetId: null
            }, function (data) {
                vm.blockList = [];
                data.forEach(function (block) {
                    vm.blockList.push({ name: block, ticked: false });
                });
            });
        }

        vm.onBlockChange = function () {
            var routeIds = [];
            vm.selectedRouteIds.forEach(function (route) {
                routeIds.push(route.id);
            });
            vm.fixedAssets = [];
            vm.spreadAssets = [];
            callAssetsRouteByID(vm.selectedStates[0].name, vm.selectedDistrict[0].name, vm.selectedBlock[0].name, routeIds, null);
        }

        function callAssetsRouteByID(state, district, block, routeIds, lastId) {
            AssetViewES.getAssets({
                state: state,
                district: district,
                block: block,
                routeIds: routeIds,
                lastAssetId: lastId
            }, function (data) {
                if (data.count >= 50) {
                    if (data.assets.FIXED != undefined || data.assets.FIXED != null) {
                        data.assets.FIXED.forEach(function (asset) {
                            vm.fixedAssets.push(asset);
                        });
                    }

                    if (data.assets.SPREAD != undefined || data.assets.SPREAD != null) {
                        data.assets.SPREAD.forEach(function (asset) {
                            vm.spreadAssets.push(asset);
                        });
                    }
                    callAssetsRouteByID(state, district, block, routeIds, data.lastId);
                } else if(data.assets == null){
                    for (var i = 0; i < markers.length; i++) {
                        markers[i].setMap(null);
                    }
                    for (var i = 0; i < polylines.length; i++) {
                        polylines[i].setMap(null);
                    }
                } else {
                    if (data.assets.FIXED != undefined || data.assets.FIXED != null) {
                        data.assets.FIXED.forEach(function (asset) {
                            vm.fixedAssets.push(asset);
                        });
                    }

                    if (data.assets.SPREAD != undefined || data.assets.SPREAD != null) {
                        data.assets.SPREAD.forEach(function (asset) {
                            vm.spreadAssets.push(asset);
                        });
                    }
                    vm.assetTypes = data.assetTypes;
                    $scope.hideAssetList();
                    showMarkers(vm.fixedAssets);
                    showPolylines(vm.spreadAssets);

                    var chartGroup = "";

                    if (vm.selectedStates != null) {
                        if (vm.selectedStates.length > 0, vm.selectedDistrict.length == 0) {
                            chartGroup = "Districts";
                        } else if (vm.selectedStates.length > 0, vm.selectedDistrict.length > 0) {
                            chartGroup = "Blocks";
                        }
                    }
                    vm.distractChartVisible = true;
                    vm.countChartVisible = true;
                    // Render the horizontal bar charts
                    renderHorizontalBarChart("distance-chart", data.assetDistance, "Distance", "Total Distance", chartGroup);
                    renderHorizontalBarChart("count-chart", data.assetCount, "GP Counts", "No of GP", chartGroup);

                    if (data.stateNames.length > 0 && district == null) {
                        vm.stateList = [];
                        data.stateNames.forEach(function (state) {
                            vm.stateList.push({ name: state });
                            if (vm.stateList.length == 1) {
                                vm.stateList[0].ticked = true;
                            }
                        });
                        vm.selectedStates = [];
                        vm.stateList.forEach(function (state) {
                            if (state.ticked) {
                                vm.selectedStates.push(state.name);
                            }
                        });
                        if (vm.selectedStates.length > 0) {
                            AssetDemographicList.getList({
                                state: vm.selectedStates[0].name,
                                district: null,
                                block: null,
                                routeIds: routeIds,
                                lastAssetId: null
                            }, function (data) {
                                vm.districtList = [];
                                data.forEach(function (district) {
                                    vm.districtList.push({ name: district, ticked: false });
                                });
                            });
                        }
                    }
                }
            });
        }

        function showMarkers(assets) {
            for (var i = 0; i < markers.length; i++) {
                markers[i].setMap(null);
            }
            if (assets.length > 0) {
                assets.forEach(function (asset) {
                    var imagePath = 'content/images/blue_circle.png';
                    vm.assetTypes.forEach(function (assettype) {
                        if (assettype.name == asset.assetTypeName && assettype.imagePath != null) {
                            imagePath = assettype.imagePath;
                        }
                    });
                    if (asset.assetCoordinates.length > 0) {
                        var marker = new google.maps.Marker({
                            position: new google.maps.LatLng(asset.assetCoordinates[0].lat, asset.assetCoordinates[0].lng),
                            map: locations.map,
                            icon: {
                                scaledSize: new google.maps.Size(iconSize, iconSize),
                                url: imagePath,
                                anchor: new google.maps.Point(
                                    iconSize / 2,
                                    iconSize / 2
                                ),
                                labelOrigin: new google.maps.Point(25, 22),
                            },
                            name: asset.phase
                        });

                        marker.setMap(locations.map);
                        google.maps.event.addListener(
                            marker,
                            "click",
                            (function (marker) {
                                var userContent = document.createElement("div");
                                var phase = "NA";
                                if(asset.phase!=null){
                                    phase = asset.phase;
                                }
                                userContent.innerHTML =
                                    '<div class="infoWindowhead">' +
                                    asset.name +
                                    '</div><div class="infoWindowContent">' +
                                    "<br><b>Asset Type </b> : " +
                                    asset.assetTypeName +
                                    "<br><b>State </b> : " +
                                    asset.assetTypeName +
                                    "<br><b>District </b> : " +
                                    asset.district +
                                    "<br><b>Block </b> : " +
                                    asset.block +
                                    "<br>"
                                "</div><br>";

                                return function () {
                                    infowindow.setContent(userContent);
                                    infowindow.open(locations.map, marker);
                                    infowindow.setPosition(
                                        new google.maps.LatLng(
                                            marker.getPosition().lat(),
                                            marker.getPosition().lng()
                                        )
                                    );
                                    setTimeout(function () {
                                        infowindow.close();
                                    }, 50000);
                                };
                            })(marker)
                        );
                        markers.push(marker);
                    }
                });

                var lastAsset = assets[assets.length - 1];
                if (lastAsset.assetCoordinates.length > 0) {
                    var assetLatLng = { lat: lastAsset.assetCoordinates[0].lat, lng: lastAsset.assetCoordinates[0].lng };
                    try {
                        locations.map.setCenter(assetLatLng);
                        locations.map.setZoom(8);
                    } catch (error) {
                        console.log(error);
                    }
                }
            }
        }

        function showPolylines(assets) {
            for (var i = 0; i < polylines.length; i++) {
                polylines[i].setMap(null);
            }
            if (assets.length > 0) {
                assets.forEach(function (asset) {
                    if (asset.assetCoordinates.length > 0) {
                        var path = [];
                        var coordinates = asset.assetCoordinates;
                        // coordinates.sort(compare);
                        asset.assetCoordinates.forEach(function (latlng) {
                            path.push({ lat: latlng.lat, lng: latlng.lng });
                        });
                        var strokeColor = "BLUE";
                        if (asset.isConnected) {
                            strokeColor = "#FD7A24";
                        }
                        var polyline = new google.maps.Polyline({
                            path: path,
                            strokeColor: strokeColor,
                            geodesic: true,
                            strokeOpacity: 1.0,
                            strokeWeight: 2.5,
                        });

                        polyline.setMap(locations.map);
                        polylines.push(polyline);
                    }
                });
                var bounds = new google.maps.LatLngBounds();
                var points = polylines[polylines.length - 1].getPath().getArray();
                for (var n = 0; n < points.length; n++) {
                    bounds.extend(points[n]);
                }
                locations.map.fitBounds(bounds);
            }
        }

        // Transform data into series for Highcharts
        function transformData(data) {
            var seriesData = [];
            Object.keys(data).forEach(function (name) {
                seriesData.push({ name: name, y: data[name] });
            });
            return seriesData;
        }

        // Render the horizontal bar chart using Highcharts
        function renderHorizontalBarChart(chartId, data, title, yLabel, xLabel) {
            try {
                Highcharts.chart(chartId, {
                    chart: {
                        type: 'bar'
                    },
                    title: {
                        text: title
                    },
                    yAxis: {
                        title: {
                            text: yLabel
                        }
                    },
                    xAxis: {
                        categories: Object.keys(data), // District names as categories
                        title: {
                            text: xLabel
                        }
                    },
                    legend: {
                        enabled: false
                    },
                    plotOptions: {
                        series: {
                            stacking: 'normal',
                            dataLabels: {
                                enabled: true,
                                format: '{point.y:.1f}' // Display value on top of each bar with 1 decimal place
                            }
                        }
                    },
                    series: [{
                        data: transformData(data)
                    }]
                });
            } catch (error) {
                console.log(error);
                if(chartId == "distance-chart"){
                    vm.distractChartVisible = false;
                } else if (chartId == "count-chart"){
                    vm.countChartVisible = false;
                }
            }

        }


        function mapStyles() {
            var styles;
            return (styles = [{ featureType: "poi", elementType: "labels.icon", stylers: [{ color: "#63666A" }] },
            { elementType: "labels.text.fill", stylers: [{ color: "#63666A", },], },
            { featureType: "transit", elementType: "labels.icon", stylers: [{ color: "#63666A" }] },
            { featureType: "road", elementType: "labels.icon", stylers: [{ visibility: "off" }] },
            { featureType: "landscape", stylers: [{ color: "#efefef" }] },
            { featureType: "road.highway", elementType: "geometry", stylers: [{ color: "#ffffff" }] }]);
        }

        function myOption(zoom, center) {
            var myOptions;
            return (myOptions = {
                zoom: zoom,
                center: center,
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: mapStyles(),
            });
        }


        $scope.complete = function () {
            $scope.hidethis = false;
        };

        $scope.hideAssetList = function () {
            $scope.hidethis = true;
        };
        $scope.fillTextbox = function (item) {
            $scope.searchAsset = item.name;
            $scope.hidethis = true;
            var assetSelected = item;

            if (assetSelected.assetCoordinates.length > 0) {
                var assetLatLng = {
                    lat:
                        assetSelected.assetCoordinates[
                            assetSelected.assetCoordinates.length -
                            1
                        ].lat,
                    lng:
                        assetSelected.assetCoordinates[
                            assetSelected.assetCoordinates.length -
                            1
                        ].lng,
                };
                try {
                    locations.map.setCenter(assetLatLng);
                } catch (error) {
                    console.log(error);
                }
            }
        };
    }
})();