(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrResource', TrResource);

	TrResource.$inject = ['$resource', 'DateUtils'];

	function TrResource($resource, DateUtils) {
		var resourceUrl = 'api/tr-resources/:id';

		return $resource(resourceUrl, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
			'get': {
				method: 'GET',
				transformResponse: function (data) {
					if (data) {
						data = angular.fromJson(data);

					}
					return data;
				}
			},
			'update': {
				method: 'PUT'
			}
		});
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrResourceGeofence', TrResourceGeofence);

	TrResourceGeofence.$inject = ['$resource'];

	function TrResourceGeofence($resource) {
		var service = $resource('/api/tr-resources/geofence/:geofenceId', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrResourceCustomTime', TrResourceCustomTime);

	TrResourceCustomTime.$inject = ['$resource'];

	function TrResourceCustomTime($resource) {
		var service = $resource('/api/tr-resources-custom/:fromDate/:toDate', {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrResourceGeofenceCustomTime', TrResourceGeofenceCustomTime);

	TrResourceGeofenceCustomTime.$inject = ['$resource'];

	function TrResourceGeofenceCustomTime($resource) {
		var service = $resource('/api/tr-resources-custom/geofence/:geofenceId/:fromDate/:toDate', {}, {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrResourceCheckAsset', TrResourceCheckAsset);

	TrResourceCheckAsset.$inject = ['$resource', 'DateUtils'];

	function TrResourceCheckAsset($resource, DateUtils) {
		var resourceUrl = 'api/assets/search/:name';

		return $resource(resourceUrl, {}, {
			'get': {
				method: 'GET',
				transformResponse: function (data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
		});
	}
})();


(function () {
	'use strict';
	angular.module('trakeyeApp').factory('ResourcesForAsset', ResourcesForAsset);

	ResourcesForAsset.$inject = ['$resource'];

	function ResourcesForAsset($resource) {
		var resourceUrl = 'api/resources-for-asset/:assetId';

		return $resource(resourceUrl, {}, {
			'query': {
				method: 'GET',
				isArray: true
			}
		});
	}
})();


(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrResourceById', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/tr-resources/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('getResourceImage', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/trakeyeresourceimages/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('getNearbyFixed', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, data, callback) {
			$http.post('api/assets/nearby-fixed/' + id, data).then(function (response) {
				callback(response.data);
			});
		};
		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrResourceAssetsById', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/tr-resources-assets/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('ResourceAttributeMappingId', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/custom-resource-type-mapper/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

/*
(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrResourceById', TrResourceById);

	TrResourceById.$inject = [ '$resource' ];

	function TrResourceById($resource) {
		var service = $resource('/api/tr-resources/:id', {}, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}

})();*/

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrResourceCountByGroup', ['$http', function ($http) {

		var service = {};
		service.getAll = function (callback) {
			$http.get('api/resourcecountbygroup').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('DownloadResourceReports', ['$http', function ($http) {

		var service = {};
		service.download = function (callback) {
			$http.get('api/download-resources').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrResourceCountByStatus', ['$http', function ($http) {

		var service = {};
		service.getAll = function (callback) {
			$http.get('api/resourcecountbystatus').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrResourcePriority', TrResourcePriority);

	TrResourcePriority.$inject = ['$resource'];

	function TrResourcePriority($resource) {
		var service = $resource('/api/tr-resources/priority/:priority/:geofenceId', {
			priority: '@priorirty'
		}, { geofenceId: '@geofenceId' }, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrResourceAttendance', TrResourceAttendance);

	TrResourceAttendance.$inject = ['$resource'];

	function TrResourceAttendance($resource) {
		var service = $resource('/api/tr-resources/attendance/:login/:fromTime/:toTime/:resourceStatus',
			{}, {}, {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrResourceSearch', TrResourceSearch);

	TrResourceSearch.$inject = ['$resource'];

	function TrResourceSearch($resource) {
		var service = $resource('/api/tr-resources/searchvalue/:search', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrResourceSearchCustom', TrResourceSearchCustom);

	TrResourceSearchCustom.$inject = ['$resource'];

	function TrResourceSearchCustom($resource) {
		var service = $resource('/api/tr-resources-custom/searchvalue/:search/:fromDate/:toDate', {}, {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrResourceGeofenceSearch', TrResourceGeofenceSearch);

	TrResourceGeofenceSearch.$inject = ['$resource'];

	function TrResourceGeofenceSearch($resource) {
		var service = $resource('/api/tr-resources/geofence-searchvalue/:geofenceId/:search', {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrResourceGeofenceSearchCustom', TrResourceGeofenceSearchCustom);

	TrResourceGeofenceSearchCustom.$inject = ['$resource'];

	function TrResourceGeofenceSearchCustom($resource) {
		var service = $resource('/api/tr-resources-custom/geofence-searchvalue/:geofenceId/:search/:fromDate/:toDate', {}, {}, {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrResourceGroup', TrResourceGroup);

	TrResourceGroup.$inject = ['$resource', 'DateUtils'];

	function TrResourceGroup($resource, DateUtils) {
		var resourceUrl = 'api/tr-resources-group';

		return $resource(resourceUrl, {
			'query': {
				method: 'GET',
				isArray: true
			}
		});
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrResourceGroupSearch', TrResourceGroupSearch);

	TrResourceGroupSearch.$inject = ['$resource'];

	function TrResourceGroupSearch($resource) {
		var service = $resource('/api/tr-resources-group/searchvalue/:search', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrResourceGroupPrioritySearch', TrResourceGroupPrioritySearch);

	TrResourceGroupPrioritySearch.$inject = ['$resource'];

	function TrResourceGroupPrioritySearch($resource) {
		var service = $resource('/api/tr-resources-group/prioritysearch/:priority/:searchtext', {
			priority: '@priorirty',
			searchtext: '@searchtext'
		}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrResourceGroupPriority', TrResourceGroupPriority);

	TrResourceGroupPriority.$inject = ['$resource'];

	function TrResourceGroupPriority($resource) {
		var service = $resource('/api/tr-resources-group/priority/:priority', {
			priority: '@priorirty'
		}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrResourceImages', TrResourceImages);

	TrResourceImages.$inject = ['$resource'];

	function TrResourceImages($resource) {
		var service = $resource('/api/resource-images/:resourceId', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();


(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrResourcesForType', TrResourcesForType);

	TrResourcesForType.$inject = ['$resource'];

	function TrResourcesForType($resource) {
		var service = $resource('/api/trresourcesbytype/:resourceTypeId', {}, {}, {
			'query': {
				method: 'GET',
				isArray: false
			},
		});

		return service;
	}

})();
(function () {
	'use strict';

	angular.module('trakeyeApp').factory('NearByBGOH', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getAll = function (data, success, error) {
			$http.post('api/tr-resources/nearby-bgoh-assets', data).then(function (response) {
				success(response.data);
			}, function (response) {
				error(response.status);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrResourceRecordings', TrResourceRecordings);

	TrResourceRecordings.$inject = ['$resource'];

	function TrResourceRecordings($resource) {
		var service = $resource('/api/resource-recordings/:resourceId', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('AllResourceTypes', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getAll = function (callback) {
			$http.get('api/resource-alltypes').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrResourcePrioritySearch', TrResourcePrioritySearch);

	TrResourcePrioritySearch.$inject = ['$resource'];

	function TrResourcePrioritySearch($resource) {
		var service = $resource('/api/tr-resources/prioritysearch/:priority/:searchtext', {
			priority: '@priorirty',
			searchtext: '@searchtext'
		}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrResourceUpdateGroup', TrResourceUpdateGroup);

	TrResourceUpdateGroup.$inject = ['$resource'];

	function TrResourceUpdateGroup($resource) {
		var resourceUrl = 'api/tr-resources/groups';

		return $resource(resourceUrl, {}, {
			'update': {
				method: 'PUT'
			}
		});
	}
})();
(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrResourceBulkDelete', TrResourceBulkDelete);

	TrResourceBulkDelete.$inject = ['$resource'];

	function TrResourceBulkDelete($resource) {
		var resourceUrl = 'api/tr-resources/bulk/delete';

		return $resource(resourceUrl, {}, {
			'save': {
				method: 'POST'
			}
		});
	}
})();


(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrResourceUser', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.gettrresourcesuser = function (callback) {
			$http.get('api/tr-resources/live-logs').then(function (response) {
				callback(response.data);
			});
		};

		service.gettrresourcesuserbyid = function (id, callback) {
			$http.get('api/tr-resources/live-logs/' + id).then(function (response) {
				callback(response.data);
			});
		};

		service.searchtrresourcesuserbyvalue = function (id, callback) {
			$http.get('api/tr-resources/search/' + id).then(function (response) {
				callback(response.data);
			});
		};
		service.gettrresourcesanduserbypriority = function (priority, callback) {
			$http.get('api/tr-resources/live-logs/priority/' + priority).then(function (response) {
				callback(response.data);
			});
		};

		service.getAgents = function (callback) {
			$http.get('api/dashboard/agentdashboard').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrResourceEdit', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getalltrresources = function (callback) {
			$http.get('api/tr-allresources').then(function (response) {
				callback(response.data);
			});
		};

		service.edittrresource = function (data, callback) {
			$http.post('api/tr-resourceedit/' + data.userId + '/' + data.resourceId, {

			}).then(function (response) {

				callback(response, response.headers);
			}, function (error) {

				callback(error, error.headers);
			});

			/*
			 * $http.post('api/tr-resourceedit/'+data.userId+'/'+data.resourceId).then(function(response){
			 * callback(response.data); });
			 */
		};

		return service;
	}]);
})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrResourceUpload', TrResourceUpload);

	TrResourceUpload.$inject = ['$http'];

	function TrResourceUpload($http) {

		var service = {};
		service.trresourceupload = function (data, callback) {

			$http.post('api/resourceimport', data, {
				transformRequest: angular.identity,
				headers: {
					'Content-Type': undefined
				}
			}).then(function (response) {

				callback(response, response.headers);
			}, function (error) {
				callback(error, error.headers);
			});
		};

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrGroupResourceSearchForMap', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.searchtrresourceformap = function (search, callback) {
			$http.get('api/tr-groupresourcesformap/searchvalue/' + search).then(function (response) {
				callback(response.data);
			});
		};
		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrGroupResourceForMap', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getAllResources = function (callback) {
			$http.get('api/tr-groupresourcesformap').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrResourceSearchForMap', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.searchtrresourceformap = function (search, callback) {
			$http.get('api/tr-resourcesformap/searchvalue/' + search).then(function (response) {
				callback(response.data);
			});
		};

		service.prioritysearchtrresourceformap = function (priority, callback) {
			$http.get('api/tr-resourcesformap/priority/' + priority).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();


(function () {
	'use strict';

	angular
		.module('trakeyeApp')
		.factory('TrResourceLastLocation', ['$rootScope', '$http', function ($rootScope, $http) {
			var service = {};

			service.getlastlogs = function (callback) {
				$http.get('api/location-logs/lastuserpath').then(function (response) {
					callback(response.data);
				});
			};
			return service;
		}]);



})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('ResourcesForMapByAdmin', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.resourcesformapbyadmin = function (adminid, callback) {
			$http.get('api/tr-resourcesformap/' + adminid).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrResourceForMap', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getAllResources = function (data, callback) {
			// $http.get('api/tr-resourcesformap').then(function (response) {
			// 	callback(response.data);
			// });
			$http.post('api/tr-resourcesformap', data).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('ShowResourceById', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.update = function (resourceid, callback) {
			$http.put('api/show-resource/' + resourceid)
				.success(function (data, status, headers, config) {
					callback(status);
				})
				.error(function (data, status, header, config) {
					callback(status);
				});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('HideResourceById', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.update = function (resourceid, callback) {
			$http.put('api/hide-resource/' + resourceid)
				.success(function (data, status, headers, config) {
					callback(status);
				})
				.error(function (data, status, header, config) {
					callback(status);
				});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('ResourceInventory', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};


		service.getResourceTxnsDates = function (id, type, callback) {
			$http.get('api/resourcedates-by-entity/' + type + '/' + id).then(function (response) {
				callback(response.data)
			})
		}
		service.getResouceTxnsByDate = function (id, type, date, callback) {
			$http.get('api/resources-by-entity-date/' + type + '/' + id + '/date/' + date).then(function (response) {
				callback(response.data)
			})
		}


		return service;
	}]);
})();

(function () {
	'use strict';

	angular
		.module('trakeyeApp')
		.factory('ResourceByDates', ResourceByDates)
		.factory('ResourceDates', ResourceDates);

		
		ResourceDates.$inject = ["$resource"];

	function ResourceDates($resource) {
		var resourceUrl = "api/resourcedates-by-entity/:type/:id";

		return $resource(
			resourceUrl,
			{ type: '@type', id: '@id' },
			{
				query: {
					method: "GET",
					isArray: true
				}
			}
		);
	}

	ResourceByDates.$inject = ["$resource", "DateUtils"];

	function ResourceByDates($resource, DateUtils) {
		var resourceUrl = "api/resources-by-entity-date/:type/:id/date/:date";

		return $resource(
			resourceUrl,
			{ type: '@type', id: '@id', date: '@date' },
			{
				query: {
					method: "GET",
					isArray: true,
				},
				get: {
					method: "GET",
					transformResponse: function (data) {
						if (data) {
							data = angular.fromJson(data);
						}
						console.log(data);
						return data;
					},
				}
			}
		);
	}

	
})();