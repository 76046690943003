(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("TrSectionDialogController", TrSectionDialogController);

    TrSectionDialogController.$inject = [
        "$timeout",
        "$scope",
        "$state",
        "entity",
        "TrSection",
        "AllSectionTypes",
        "AllCustomSectionTypeAttribute",
        "Configs",
        "route",
        "postRoutePoints",
        "AlertService",
        "$window",
        "$rootScope",
        "assetsByRouteId"
    ];

    function TrSectionDialogController(
        $timeout,
        $scope,
        $state,
        entity,
        TrSection,
        AllSectionTypes,
        AllCustomSectionTypeAttribute,
        Configs,
        route,
        postRoutePoints,
        AlertService,
        $window,
        $rootScope,
        assetsByRouteId
    ) {
        var vm = this;
        vm.trSection = entity;
        vm.save = save;
        var entity_old = angular.copy(vm.trSection);
        vm.selectAttributes = selectedSectionTypeAttributes;
        vm.sectionType = entity.sectionType;
        var markers = []
        var coordinateList = []
        var chainageList = []
        var markerList = []
        var iconSize = 20
        var labelFrom, labelTo
        labelFrom = 'From Section'
        labelTo = 'To Section'
        vm.isSetUpdate = false
        var infowindow = new google.maps.InfoWindow();
        var openedInfoWindow = null;
        var btnAddSection;
        var mappedAssetTemp = {};
        var cyclePolylines = []
        var minCostPolylines = []

        if ($state.params.routeId) {
            vm.trSection.routeId = parseInt($state.params.routeId, 10);
        }

        vm.customAttributesMap = {};
        vm.customAttributesMapp = [];
        loadPage();
        var locations = {};
        var locationsEnd = {};


        if (vm.trSection.id != null) {
            vm.trSectionType = vm.trSection.sectionType;
        }

        if (!vm.trSection.id) {
            vm.trSection.routeName = route.name
            vm.trSection.status = "NEW"
            vm.trSection.phase = "PLANNING"
        }


        Configs.get("RolloutTaskStatus", function (response) {
            vm.rolloutTaskStatus = response;
        });

        function loadPage() {

            vm.imageInNewTab = function (img) {
                var imgWindow = window.open();
                imgWindow.document.write(
                    '<img width="25%"   src="data:image/jpeg;base64,' +
                    img +
                    '">'
                );
                imgWindow.document.close();
            };

            var sectionTypeAttributeValuesList =
                vm.trSection.sectionTypeAttributeValues;

            try {
                for (var index in vm.trSection.sectionType.sectionTypeAttribute) {
                    if (
                        !checkIfSectionTypeExists(
                            vm.trSection.sectionType.sectionTypeAttribute[index],
                            vm.trSection.sectionTypeAttributeValues
                        )
                    ) {
                        var sectionTypeAttribute1 =
                            vm.trSection.sectionType.sectionTypeAttribute[index];
                        sectionTypeAttributeValuesList.push({
                            sectionTypeAttribute: sectionTypeAttribute1,
                            attributeValue:
                                vm.trSection.sectionType.sectionTypeAttribute[index]
                                    .defaultValue,
                        });
                    }
                }
            } catch (e) { }

            function checkIfSectionTypeExists(sectionType, sectionTypeAttrVal) {
                var hasSectionType = false;
                for (var i = 0; i < sectionTypeAttrVal.length; i++) {
                    var name = sectionTypeAttrVal[i].sectionTypeAttribute.name;
                    if (name == sectionType.name) {
                        hasSectionType = true;
                        break;
                    }
                }
                return hasSectionType;
            }
            AllSectionTypes.getAll(function (response) {
                vm.sectionTypes = response;
            });

            AllCustomSectionTypeAttribute.getAll(function (response) {
                //vm.customSectionTypeAttributes = response;
                response.forEach(function (arrayItem) {
                    if (arrayItem.customSectionTypeAttributeValues != undefined) {
                        vm.customAttributesMap[arrayItem.name] =
                            arrayItem.customSectionTypeAttributeValues;
                        vm.customAttributesMap[arrayItem.name].parentId =
                            arrayItem.id;
                    }

                });
                if (vm.trSection.id) {
                    $(":file").filestyle({
                        buttonBefore: true,
                    });
                    $(":file").filestyle("buttonText", "Browse File");
                }
            });


        }
        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function selectedSectionTypeAttributes() {
            vm.showFilter = true;
            if (vm.sectionType) {
                vm.trSection.sectionType = vm.sectionType;
                if (
                    entity_old.sectionType &&
                    vm.sectionType.id === entity_old.sectionType.id
                ) {
                    vm.trSection.sectionTypeAttributeValues = [];
                    vm.trSection.sectionTypeAttributeValues =
                        entity_old.sectionTypeAttributeValues;
                } else {
                    vm.trSection.sectionTypeAttributeValues = [];
                    $.each(
                        vm.sectionType.sectionTypeAttribute,
                        function (key, value) {
                            vm.trSection.sectionTypeAttributeValues.push({
                                sectionTypeAttribute:
                                    vm.sectionType.sectionTypeAttribute[key],
                            });
                        }
                    );
                }
            }
        }

        function save() {
            //vm.isSaving = true;
            //var spreadAV = vm.trSection.sectionTypeAttributeValues;
            /*  for (
                 var spreadAssetIndex = 0;
                 spreadAssetIndex < spreadAV.length;
                 spreadAssetIndex++
             ) {
                 if (
                     spreadAV[spreadAssetIndex].sectionTypeAttribute
                         .name === "Fiber Distance (km)"
                 ) {
                     spreadAV[
                         spreadAssetIndex
                     ].attributeValue = fiberDistance;
                 }
             }
             vm.trSection.sectionTypeAttributeValues = spreadAV; */
            callTask()
            /*   if (vm.trSection.id !== null) {
  
                  if(vm.isSetUpdate){
                      if(markers.length == 2){
                          var payload
                          markers.forEach(function (item){
          
                              if(item.label.text == labelFrom || item.label.text == labelTo){
                                  payload = { "latitude" : item.position.lat(), "longitude" : item.position.lng()}
                                  postRoutePoints.post(payload,function(response){
          
                                      if(item.label.text == labelFrom){
                                          vm.trSection.fromRoutePoint = {
                                              id: response.id,
                                          };
                                      }
          
                                      if(item.label.text == labelTo){
                                          vm.trSection.toRoutePoint = {
                                              id: response.id,
                                          };
          
                                          if(vm.trSection.fromRoutePoint && vm.trSection.toRoutePoint){
                                              setTimeout(function(){ TrSection.update(vm.trSection, onSaveSuccess, onSaveError); }, 1500);
                                              
                                          }else{
                                              AlertService.error("Error");
                                          }
                                          
                                      }
                                     
          
                                     
                                  },function(error){
                                      AlertService.error(error.data.message);
                                  })
                              }
                          })
                      }else{
                          alert("Section From or To Points are Empty")
                      }
                  }else{
                      if(markers.length == 2){
                          TrSection.update(vm.trSection, onSaveSuccess, onSaveError);
                      }else{
                         alert("Section From or To Points are Empty")
                      }
                  }
                  
              } else{
                  if(markers.length == 2){
                      var payload
                      markers.forEach(function (item){
      
                          if(item.label.text == labelFrom || item.label.text == labelTo){
                              payload = { "latitude" : item.position.lat(), "longitude" : item.position.lng()}
                              postRoutePoints.post(payload,function(response){
      
                                  if(item.label.text == labelFrom){
                                      vm.trSection.fromRoutePoint = {
                                          id: response.id,
                                      };
                                  }
      
                                  if(item.label.text == labelTo){
                                      vm.trSection.toRoutePoint = {
                                          id: response.id,
                                      };
                                      callTrsectionSave()
                                  }
                                 
      
                                 
                              },function(error){
                                  AlertService.error(error.data.message);
                              })
                          }
                      })
                  }else{
                      alert("Section From or To Points are Empty")
                  }
              } */


        }

        /*   function callTrsectionSave(){
              if(vm.trSection.fromRoutePoint && vm.trSection.toRoutePoint){
                  setTimeout(function(){ TrSection.save(vm.trSection, onSaveSuccess, onSaveError); }, 2500);
              }else{
                  AlertService.error("Error");
              }
          }
  
          function onSaveSuccess(result) {
              $scope.$emit("trakeyeApp:sectionUpdate", result);
              //vm.isSaving = false;
  
              var pageValue = sessionStorage.getItem("prevPage");
              if (pageValue == "user-management.new") {
                  $state.go("user-management.new");
              } else {
                  $window.history.back();
              }
          }
  
          function onSaveError() {
              //vm.isSaving = false;
          } */

        function callTask() {
            if (markers.length == 2) {
                markers.forEach(function (item) {
                    if (item.label.text == labelFrom || item.label.text == labelTo) {
                        if (item.label.text == labelFrom) {
                            if (vm.trSection.id) {
                                vm.trSection.fromRoutePoint.latitude = item.position.lat()
                                vm.trSection.fromRoutePoint.longitude = item.position.lng()
                            } else {
                                vm.trSection.fromRoutePoint = {
                                    "latitude": item.position.lat(), "longitude": item.position.lng()
                                };
                            }
                        }

                        if (item.label.text == labelTo) {
                            if (vm.trSection.id) {
                                vm.trSection.toRoutePoint.latitude = item.position.lat()
                                vm.trSection.toRoutePoint.longitude = item.position.lng()
                            } else {
                                vm.trSection.toRoutePoint = {
                                    "latitude": item.position.lat(), "longitude": item.position.lng()
                                };
                            }
                        }
                    }
                })

                markersNearby.forEach(function (item) {
                    if (item.label.text == 'From Nearby') {
                        if (vm.trSection.id) {
                            vm.trSection.fromNearestRoutePoint.latitude = item.position.lat()
                            vm.trSection.toNearestRoutePoint.longitude = item.position.lng()
                        } else {
                            vm.trSection.fromNearestRoutePoint = {
                                "latitude": item.position.lat(), "longitude": item.position.lng()
                            };
                        }
                    }

                    if (item.label.text == 'To Nearby') {
                        if (vm.trSection.id) {
                            vm.trSection.fromNearestRoutePoint.latitude = item.position.lat()
                            vm.trSection.toNearestRoutePoint.longitude = item.position.lng()
                        } else {
                            vm.trSection.toNearestRoutePoint = {
                                "latitude": item.position.lat(), "longitude": item.position.lng()
                            };
                        }
                    }
                })
                if (vm.trSection.id) {
                    TrSection.update(vm.trSection, onSaveSuccess, onSaveError)
                } else {
                    TrSection.save(vm.trSection, onSaveSuccess, onSaveError)
                }
            } else {
                alert("Section From or To Points are Empty")
            }
        }

        function onSaveSuccess(result) {
            $window.history.back();
        }

        function onSaveError() {
        }

        Configs.get("Phases", function (response) {
            vm.workFlowPhase = response;
            //vm.workFlow.phase = "Planning Phase"
            /*  if(vm.workFlow.id == null){
                response.forEach(function(val){
                    if(val.configValue == '01'){
                        vm.workFlow.phase = val.configKey
                    }
                })
            }*/
        });

        //start

        locations.map = new google.maps.Map(
            document.getElementById("map_canvasStart"),
            {
                center: {
                    lat: parseFloat($rootScope.DEFAULT_LATITUDE),
                    lng: parseFloat($rootScope.DEFAULT_LONGITUDE),
                },
                zoom: $rootScope.rolloutMapInitZoom,
                fullscreenControl: true,
                styles: mapStyles(),
            }
            //myOption(16, vm.getCenterStart)
        );


        google.maps.event.addListener(locations.map, "click", function (event) {
            if (markers.length < 2) {
                // Add a marker to the map
                addMarker(event.latLng);
            } else {
                console.log("Maximum 2 markers allowed.");
            }
        });
        //geoLocation(locations.map);

        //vm.getCenterStart = locations.map.center;

        //end
        /* 
        locationsEnd.map = new google.maps.Map(
            document.getElementById("map_canvasEnd"),
            {
                center: {
                    lat: parseFloat($rootScope.DEFAULT_LATITUDE),
                    lng: parseFloat($rootScope.DEFAULT_LONGITUDE),
                },
                zoom: 13,
                fullscreenControl: true,
                styles: mapStyles(),
            }
            //myOption(16, vm.getCenterEnd)
        );
        geoLocation(locationsEnd.map); */
        //vm.getCenterEnd = locationsEnd.map.center;

        function geoLocation(map) {
            var infoWindow = new google.maps.InfoWindow();
            var locationButton = document.createElement("button");
            locationButton.setAttribute("title", "Locate Me");
            locationButton.classList.add("custom-map-control-button");
            var geoIcon = document.createElement("i");
            geoIcon.classList.add("fa");
            geoIcon.classList.add("fa-crosshairs");
            geoIcon.classList.add("geo");
            locationButton.appendChild(geoIcon);
            map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
                locationButton
            );

            var goTo = document.createElement("span");
            goTo.setAttribute("title", "Location Coordinates");
            goTo.classList.add("custom-map-control-button");
            var gotoIcon = document.createElement("i");
            gotoIcon.classList.add("fa");
            gotoIcon.classList.add("fa-location-arrow");
            gotoIcon.classList.add("geo");
            gotoIcon.classList.add("mt-5");
            goTo.appendChild(gotoIcon);
            map.controls[google.maps.ControlPosition.RIGHT_TOP].push(goTo);
            goTo.addEventListener("click", function () {
                vm.removeMarker()
                $("#modalLatLongs").show();
            });

            var goToNearBy = document.createElement("span");
            goToNearBy.setAttribute("title", "Add Nearby Points");
            goToNearBy.classList.add("custom-map-control-button");
            var goToNearByIcon = document.createElement("i");
            goToNearByIcon.classList.add("fa");
            goToNearByIcon.classList.add("fa-thumb-tack");
            goToNearByIcon.classList.add("geo");
            goToNearByIcon.classList.add("mt-10");
            goToNearBy.appendChild(goToNearByIcon);
            map.controls[google.maps.ControlPosition.LEFT_TOP].push(goToNearBy);
            goToNearBy.addEventListener("click", function () {
                //vm.removeMarker()
                vm.isShowNearbyAdd = true
            });

            locationButton.addEventListener("click", function () {
                // Try HTML5 geolocation.
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            var pos = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            };
                            infoWindow.setPosition(pos);
                            infoWindow.setContent("Location found.");
                            infoWindow.open(map);
                            map.setCenter(pos);
                            map.setZoom(18);
                        },
                        function () {
                            handleLocationError(
                                true,
                                infoWindow,
                                map.getCenter()
                            );
                        },
                        {
                            enableHighAccuracy: true,
                            timeout: 5000,
                            maximumAge: 0,
                        }
                    );
                } else {
                    // Browser doesn't support Geolocation
                    handleLocationError(false, infoWindow, map.getCenter());
                }
            });

            function handleLocationError(
                browserHasGeolocation,
                infoWindow,
                pos
            ) {
                infoWindow.setPosition(pos);
                infoWindow.setContent(
                    browserHasGeolocation
                        ? "Error: The Geolocation service failed."
                        : "Error: Your browser doesn't support geolocation."
                );
                infoWindow.open(map);
            }
        }

        function mapStyles() {
            var styles;
            return (styles = [
                {
                    featureType: "poi",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    elementType: "labels.text.fill",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                } /* 
                {
                    elementType: "geometry.fill",
                    stylers: [
                        {
                            color: "#EFEFEF",
                        },
                    ],
                }, */,
                {
                    featureType: "transit",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    featureType: "road",
                    elementType: "labels.icon",
                    stylers: [{ visibility: "off" }],
                },
                {
                    featureType: "landscape",
                    stylers: [
                        {
                            color: "#efefef",
                        },
                    ],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry",
                    stylers: [
                        {
                            color: "#ffffff",
                        },
                    ],
                },
            ]);
        }

        function myOption(zoom, center) {
            var myOptions;
            return (myOptions = {
                zoom: zoom,
                center: center,
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: mapStyles(),
            });
        }

        vm.removeMarker = function () {
            markers.forEach(function (marker) {
                marker.setMap(null);
            });

            // Clear the markers array
            markers = [];

            markersNearby.forEach(function (marker) {
                marker.setMap(null);
            });

            // Clear the markers array
            markersNearby = [];

            vm.isShowNearbyAdd = false
            if (vm.trSection.id) {
                vm.isSetUpdate = true
            }

        }

        if (route && route.routePoints != null && route.routePoints.length) {
            var grouped = route.routePoints.reduce(function (acc, current) {
                var assetId = current.assetId;
                if (!acc[assetId]) {
                    acc[assetId] = [];
                }
                acc[assetId].push(current);
                return acc;
            }, {});
            var groupedByAssetId = Object.values(grouped);

            groupedByAssetId.forEach(function (routePoints) {
                routePoints.sort(function (a, b) { return a.routePointNumber - b.routePointNumber });

                routePoints.forEach(function (each) {
                    if (each.routePointType == "COORDINATE") {
                        coordinateList.push({ lat: each.latitude, lng: each.longitude })
                    }/* else if(each.routePointType == "MARKER"){
                       markerList.push({lat:each.latitude,lng:each.longitude})
                   } */else if (each.routePointType == "CHAINAGE") {
                        chainageList.push({ latitude: each.latitude, longitude: each.longitude, type: each.routePointType, number: each.routePointNumber })
                    }
                })

                if (coordinateList.length) {
                    locations.map.setCenter(coordinateList[0]);
                    var polyline = new google.maps.Polyline({
                        path: coordinateList,
                        geodesic: true,
                        strokeColor: '#FD7A24',
                        strokeOpacity: 1.0,
                        strokeWeight: 4,
                    });

                    // Set the map to display the polyline
                    polyline.setMap(locations.map);
                    addClick(polyline)
                }

                /*  if(markerList.length){
                     locations.map.setCenter(markerList[0]);
                     createMarkers(markerList,'content/images/markersPoint.png',locations.map)
                 } */

                if (chainageList.length) {
                    locations.map.setCenter(chainageList[0]);
                    createMarkers(chainageList, 'content/images/chainagePoints.png', locations.map)
                }
            });

        }


        //Functions

        function addClick(item) {
            // Add a click event listener to the map
            item.addListener("click", function (event) {
                if (markers.length < 2) {
                    // Add a marker to the map
                    addMarker(event.latLng);

                    // Get the latitude and longitude of the clicked point
                    vm.pinLat = event.latLng.lat();
                    vm.pinLong = event.latLng.lng();
                } else {
                    console.log("Maximum 2 markers allowed.");
                }

                if (vm.isShowNearbyAdd && markersNearby.length < 2 && markers.length == 2) {
                    addMarkerNearBy(event.latLng)
                }
            })
        }


        function createMarkers(arr, img, map) {
            arr.forEach(function (each) {
                var marker = new google.maps.Marker({
                    position: { lat: each.latitude, lng: each.longitude },
                    map: map,
                    icon: {
                        scaledSize: new google.maps.Size(iconSize, iconSize),
                        url: img,
                        anchor: new google.maps.Point(
                            iconSize / 2,
                            iconSize / 2
                        ),
                        labelOrigin: new google.maps.Point(25, 22),
                    },
                });

                google.maps.event.addListener(
                    marker,
                    "click",
                    (function () {
                        //var name = (each.name == (undefined || null)) ? " " : each.name
                        if (openedInfoWindow != null) {
                            openedInfoWindow.close();
                        }
                        var userContent = document.createElement("div")

                        userContent.innerHTML = '<div class="infoWindowhead"></br></br>' +
                            /* "<b>Name : "+name+"</b></br>"+ */
                            "<b>Latitude : " + each.latitude + "</b></br>" +
                            "<b>Longitude : " + each.longitude + "</b></br>" +
                            "<b>Type : " + each.type + "</b></br>" +
                            "<b>Number : " + each.number + "</b></br>" +
                            "</br></div>"
                        infowindow.setContent(
                            userContent
                        );
                        infowindow.open(map);
                        infowindow.setPosition({
                            lat: each.latitude,
                            lng: each.longitude,
                        });
                        openedInfoWindow = infowindow;


                        if (markers.length < 2) {
                            btnAddSection = userContent.appendChild(
                                document.createElement("input")
                            );
                            btnAddSection.type = "button";

                            btnAddSection.value = "Add Section Point";

                            btnAddSection.className = "button";

                            btnAddSection.addEventListener("click", function () {
                                if (markers.length < 2) {
                                    // Add a marker to the map
                                    addMarker({
                                        lat: each.latitude,
                                        lng: each.longitude,
                                    });

                                    // Get the latitude and longitude of the clicked point
                                    vm.pinLat = each.latitude;
                                    vm.pinLong = each.longitude;
                                    openedInfoWindow.close();
                                } else {
                                    alert("From and To Section Exists");
                                }
                            })
                        }

                    }))


                arr.push(marker);
            })
        }

        function addMarker(location) {
            var markerText
            if (markers.length == 0) {
                markerText = labelFrom
            } else if (markers.length == 1) {
                markerText = labelTo
            }
            // Create a marker
            var marker = new google.maps.Marker({
                position: location,
                map: locations.map,
                icon: {
                    scaledSize: new google.maps.Size(iconSize, iconSize),
                    url: "content/images/sections.png",
                    anchor: new google.maps.Point(
                        iconSize / 2,
                        -4
                    ),
                    labelOrigin: new google.maps.Point(25, 22),
                },
                label: {
                    text: markerText,
                },
                draggable: true
            });

            // Add the marker to the markers array
            markers.push(marker);
        }


        if (vm.trSection.id && vm.trSection.fromRoutePoint && vm.trSection.toRoutePoint) {
            addMarker({ lat: vm.trSection.fromRoutePoint.latitude, lng: vm.trSection.fromRoutePoint.longitude })
            addMarker({ lat: vm.trSection.toRoutePoint.latitude, lng: vm.trSection.toRoutePoint.longitude })
            locations.map.setCenter({ lat: vm.trSection.fromRoutePoint.latitude, lng: vm.trSection.fromRoutePoint.longitude });
        }

        // if(route && route.assets.length){
        //     route.assets.forEach(function (element){
        //                 if(element.assetCoordinates.length){
        //                     locations.map.setCenter({lat:element.assetCoordinates[0].latitude, lng:
        //                         element.assetCoordinates[0].longitude} );
        //                     if(element.assetType.layout == "SPREAD"){
        //                         vm.tempPath = []
        //                     element.assetCoordinates.sort(function(a, b) { return a.sortOrder - b.sortOrder});
        //                     element.assetCoordinates.forEach(function (item){
        //                         vm.tempPath.push({lat:item.latitude,lng:item.longitude})
        //                     })
        //                     var polyline = new google.maps.Polyline({
        //                         path: vm.tempPath,
        //                         geodesic: true,
        //                         strokeColor: element.assetType.colorcode,
        //                         strokeOpacity: 0.6,
        //                         strokeWeight: 4,
        //                     });
        //                     addClick(polyline);
        //                     // Set the map to display the polyline
        //                     polyline.setMap(locations.map);
        //                     }

        //                     if(element.assetType.layout == "FIXED"){
        //                         element.assetCoordinates.forEach(function (e) {
        //                             var marker = new google.maps.Marker({
        //                             position:  {lat:e.latitude,lng:e.longitude},
        //                             map: locations.map,
        //                             icon: {
        //                                 scaledSize: new google.maps.Size(iconSize, iconSize),
        //                                 url: element.assetType.imagePath,
        //                                 anchor: new google.maps.Point(
        //                                     iconSize / 2,
        //                                     iconSize / 2
        //                                 ),
        //                                 labelOrigin: new google.maps.Point(25, 22),
        //                             },
        //                         });
        //                         google.maps.event.addListener(
        //                             marker,
        //                             "click",
        //                             (function () {
        //                                 //var name = (each.name == (undefined || null)) ? " " : each.name
        //                                 if (openedInfoWindow != null) {
        //                                     openedInfoWindow.close();
        //                                 }
        //                                 var userContent = document.createElement("div")

        //                                 userContent.innerHTML = '<div class="infoWindowhead"></br></br>' +
        //                                 // /* "<b>Name : "+name+"</b></br>"+ */
        //                                 "<b>Latitude : "+e.latitude+"</b></br>"+
        //                                 "<b>Longitude : "+e.longitude+"</b></br>"+
        //                                 "</br></div>"
        //                                 infowindow.setContent(
        //                                     userContent
        //                                 );

        //                                 infowindow.open(locations.map);
        //                                 infowindow.setPosition({
        //                                     lat: e.latitude,
        //                                     lng: e.longitude,
        //                                 });
        //                                 openedInfoWindow = infowindow;

        //                                 if (markers.length < 2) {
        //                                     btnAddSection = userContent.appendChild(
        //                                         document.createElement("input")
        //                                     );
        //                                     btnAddSection.type = "button";

        //                                     btnAddSection.value = "Add Section Point";

        //                                     btnAddSection.className = "button";

        //                                     btnAddSection.addEventListener("click", function () {
        //                                         if (markers.length < 2) {
        //                                             // Add a marker to the map
        //                                             addMarker({
        //                                                 lat: e.latitude,
        //                                                 lng: e.longitude,
        //                                             });

        //                                             // Get the latitude and longitude of the clicked point
        //                                             vm.pinLat = e.latitude;
        //                                             vm.pinLong = e.longitude;
        //                                             openedInfoWindow.close();
        //                                         } else {
        //                                             alert("From and To Task Exists");
        //                                         }
        //                                        })
        //                                  }
        //                              }))


        //                         })
        //                       }
        //                     }

        //                 })

        // }

        vm.objectRouteImg = {}
        vm.objectRouteImg.routeId = vm.trSection.routeId
        vm.objectRouteImg.map = locations.map

        if (vm.trSection.routeId) {
            vm.mappedAssetsList = []
            vm.storeAssetTypes = []
            // callAssetsRouteByID(vm.trSection.routeId, null)
        }

        function callAssetsRouteByID(routeId, lastId) {
            assetsByRouteId.get(routeId, lastId, function (data) {
                if (data.assets.length) {
                    vm.isShowing = false
                    vm.tempStoreAssets = data.assets
                    data.assets.forEach(function (item) {
                        //item.isChecked = true

                        item.assetType = {}
                        item.assetType.layout = item.assetTypeLayout
                        item.assetType.imagePath = item.assetTypeImagePath
                        item.assetType.name = item.assetTypeName

                        if (vm.storeAssetTypes.length) {
                            vm.storeAssetTypes.filter(function (eachElement) {
                                if (item.assetTypeName == eachElement.name) {
                                    item.assetTypeAttributeValues = getConsolidatedAssetTypeAttributeValue(eachElement.assetTypeAttributes)
                                }
                            });
                        }


                        delete item.assetTypeName;
                        delete item.assetTypeImagePath;
                        delete item.assetTypeLayout;

                        vm.mappedAssetsList.push(item)
                    })


                    for (var i = 0; i < vm.mappedAssetsList.length; i++) {
                        if (vm.mappedAssetsList[i].assetType.layout == "FIXED") {
                            drawMarkers(vm.mappedAssetsList[i], locations.map, i)
                        } else if (
                            vm.mappedAssetsList[i].assetType.layout == "SPREAD"
                        ) {
                            if (vm.mappedAssetsList[i].assetCoordinates) {
                                var assetCoordinateValues = []
                                vm.mappedAssetsList[i].assetCoordinates.forEach(function (each) {
                                    assetCoordinateValues.push({ lat: each.latitude, lng: each.longitude })
                                })
                            }

                            var tmpColor = "red";
                            if (vm.mappedAssetsList[i].isCycle) {
                                tmpColor = "#FFD700"
                            } else if (!vm.mappedAssetsList[i].connected) {
                                tmpColor = "blue";
                            }

                            if (vm.mappedAssetsList[i].inGraph) {
                                drawPolyLines(vm.mappedAssetsList[i], tmpColor, assetCoordinateValues, locations.map)
                            }
                        }
                    }

                    if (vm.mappedAssetsList.length > 0) {
                        var len = Math.floor(vm.mappedAssetsList.length / 2);
                        locations.map.setCenter(
                            new google.maps.LatLng(
                                vm.mappedAssetsList[len].assetCoordinates[0].latitude,
                                vm.mappedAssetsList[len].assetCoordinates[0].longitude
                            )
                        );
                    }
                }

                if (data.assets.length > 500) {
                    callAssetsRouteByID(vm.trSection.routeId, data.assets[data.assets.length - 1].id)
                }
            })
        }

        function drawMarkers(asset, map, index) {
            var tmpStatus = asset.connected ? "<br><b> Status </b>" + asset.connected : "";
            var tempPhase = asset.phase ? "<br><b> Phase : </b>" + asset.phase : "";
            iconSize = 40
            var marker = new google.maps.Marker({
                position: {
                    lat: asset.assetCoordinates[0].latitude,
                    lng: asset.assetCoordinates[0].longitude,
                },
                map: map,
                icon: {
                    scaledSize: new google.maps.Size(
                        iconSize,
                        iconSize
                    ),
                    url: asset.assetType.imagePath,
                    anchor: new google.maps.Point(
                        iconSize / 2,
                        iconSize / 2
                    ),
                    labelOrigin: new google.maps.Point(25, 40),
                },
                label: {
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "14px",
                    text: asset.name,
                },
            });

            google.maps.event.addListener(
                marker,
                "click",
                (function (marker) {
                    var assetDetails = asset;
                    var userContent = document.createElement(
                        "div"
                    ),
                        btnEditAsset, btnDeleteAsset;


                    userContent.innerHTML =
                        '<div class="infoWindowhead">' +
                        assetDetails.name +
                        '</div><div class="infoWindowContent">' +
                        "<b>Asset Type </b> : " +
                        assetDetails.assetType.name +
                        "<br><b>Latitude </b> : " +
                        assetDetails.assetCoordinates[0]
                            .latitude +
                        "<br><b>Longitude </b> : " +
                        assetDetails.assetCoordinates[0]
                            .longitude +
                        tempPhase +
                        tmpStatus
                    "<br>" +
                        "</div><br>";

                    if (markers.length < 2) {
                        btnAddSection = userContent.appendChild(
                            document.createElement("input")
                        );
                        btnAddSection.type = "button";

                        btnAddSection.value = "Add Section Point";

                        btnAddSection.className = "button";

                        btnAddSection.addEventListener("click", function () {
                            if (markers.length < 2) {
                                // Add a marker to the map
                                addMarker({
                                    lat: assetDetails.assetCoordinates[0]
                                        .latitude,
                                    lng: assetDetails.assetCoordinates[0]
                                        .longitude,
                                });

                                // Get the latitude and longitude of the clicked point
                                vm.pinLat = assetDetails.assetCoordinates[0]
                                    .latitude;
                                vm.pinLong = assetDetails.assetCoordinates[0]
                                    .longitude;
                                openedInfoWindow.close();
                            } else {
                                alert("From and To Section Exists");
                            }
                        })
                    }

                    /* if (markersConnect.length != 2) {
                        btnEditAsset = userContent.appendChild(
                            document.createElement("input")
                        );

                        btnEditAsset.type = "button";
                        btnEditAsset.value = "Connect";
                        btnEditAsset.className = "button";

                        google.maps.event.addDomListener(
                            btnEditAsset,
                            "click",
                            function () {
                                addMarker(
                                    {
                                        lat:
                                            assetDetails
                                                .assetCoordinates[0]
                                                .latitude,
                                        lng:
                                            assetDetails
                                                .assetCoordinates[0]
                                                .longitude,
                                    },
                                    assetDetails.name,
                                    each.connected
                                );

                                //$(":file").filestyle("clear");
                                //$("#connectAssetsModal").show();
                            }
                        );
                    } */


                    return function () {
                        infowindow.setContent(userContent);
                        infowindow.open(locations.map, marker);
                        infowindow.setPosition(
                            new google.maps.LatLng(
                                marker.getPosition().lat(),
                                marker.getPosition().lng()
                            )
                        );
                        setTimeout(function () {
                            infowindow.close();
                        }, 6000);
                    };
                })(marker, index)
            );
        }

        function drawPolyLines(asset, color, path, map) {
            var tempMarker = []
            var tmpStatus = asset.strStatus ? "<br><b> Status </b><br>" + asset.strStatus : "";

            var distance = asset.distance ? "<br><b> Distance </b><br>" + asset.distance : "";
            var distanceText = (asset.distance && asset.name) ? asset.name + "(" + asset.distance + ")" : ''

            var strokeWeight = asset.isCycle ? 23 : 3
            var strokeOpacity = asset.isCycle ? 0.6 : 1
            var polyline = new google.maps.Polyline({
                path: path,
                geodesic: true,
                strokeColor: color,
                strokeOpacity: strokeOpacity,
                strokeWeight: strokeWeight,
            });
            addClick(polyline);
            //displaying length
            var len = Math.floor(path.length / 2)


            var marker = new google.maps.Marker({
                position: path[len],
                map: map,
                label: '',
                icon: {
                    url: 'content/images/iconTransparent.png',
                }

            });
            tempMarker.push(marker)

            google.maps.event.addListener(
                locations.map,
                "zoom_changed",
                function () {

                    if (distanceText) {
                        if (locations.map.getZoom() == 14) {
                            if (tempMarker.length) {
                                tempMarker.forEach(function (item) {
                                    item.setLabel('');
                                })
                            }
                        } else if (locations.map.getZoom() == 15) {
                            if (tempMarker.length) {
                                tempMarker.forEach(function (item) {
                                    var label = { text: distanceText, color: 'black', fontWeight: 'bold', fontSize: "11px" };
                                    item.setLabel(label);
                                })
                            }

                        }
                    }

                })

            google.maps.event.addListener(
                polyline,
                "click",
                function (event) {
                    if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                        if (spreadPoly != null) {
                            spreadPoly.setMap(null);
                        }
                    }

                    clearTimeout(polylineTimer);

                    spreadPoly = new google.maps.Polyline({
                        path: path,
                        strokeColor: "#FFFF00",
                        strokeOpacity: 0.6,
                        strokeWeight: 19,
                    });

                    spreadPoly.setMap(map);

                    polylineTimer = setTimeout(function () {
                        if (spreadPoly != null) {
                            spreadPoly.setMap(null);
                            spreadPoly = null;
                        }
                    }, 5000);

                    var userContent = document.createElement(
                        "div"
                    ),
                        btnEditAsset,
                        brBreak, btnDeleteAsset;

                    userContent.innerHTML =
                        '<div class="infoWindowhead">' +
                        "<b> Name </b><br>" +
                        asset.name + tmpStatus + distance +
                        "<br><b>Latitude : </b>" + event.latLng.lat() +
                        "<br><b>Longitude : </b>" + event.latLng.lng() +
                        "</br></div>";


                    /* btnEditAsset = userContent.appendChild(
                 document.createElement("input")
                 );

                 btnEditAsset.type = "button";
                 btnEditAsset.value = "Edit Asset";
                 btnEditAsset.className = "button";

             google.maps.event.addDomListener(
                 btnEditAsset,
                 "click",
                 function () {
                     $(":file").filestyle("clear");
                     $("#assetEditModal").show();
                 }
             );  */

                    infowindow.setContent(userContent);

                    infowindow.open(map);
                    infowindow.setPosition(event.latLng);
                    openedInfoWindow = infowindow;
                }
            );

            if (asset.isCycle) {
                cyclePolylines.push(polyline)
            }

            if (asset.minCost) {
                minCostPolylines.push(polyline)
            }


            polyline.setMap(map);
        }

        geoLocation(locations.map);

        vm.closeModal = function (id) {
            vm.getLocationCoordinateA = null
            vm.getLocationCoordinateB = null

            $("#" + id).removeClass("in");
            $(".modal-backdrop").remove();
            $("#" + id).hide();
        }

        vm.onLocationCoordinates = function () {
            var latlngA = vm.getLocationCoordinateA.split(",");
            var latlngB = vm.getLocationCoordinateB.split(",");

            addMarker({ lat: parseFloat(latlngA[0]), lng: parseFloat(latlngA[1]) })
            addMarker({ lat: parseFloat(latlngB[0]), lng: parseFloat(latlngB[1]) })

            locations.map.setCenter({ lat: parseFloat(latlngB[0]), lng: parseFloat(latlngB[1]) })
            vm.closeModal('modalLatLongs')
        }

        var markersNearby = []
        function addMarkerNearBy(location) {
            var markerText
            if (markersNearby.length == 0) {
                markerText = "From Nearby"
            } else if (markersNearby.length == 1) {
                markerText = "To Nearby"
            }
            // Create a marker
            var marker = new google.maps.Marker({
                position: location,
                map: locations.map,
                icon: {
                    scaledSize: new google.maps.Size(iconSize, iconSize),
                    url: "content/images/pin24.png",
                    anchor: new google.maps.Point(
                        iconSize / 2,
                        -4
                    ),
                    labelOrigin: new google.maps.Point(25, 22),
                },
                label: {
                    text: markerText,
                },
                draggable: true
            });

            // Add the marker to the markers array
            markersNearby.push(marker);
        }

        // if(vm.trSection.assets != null && vm.trSection.assets.length){
        //     var polylineSection,spreadPoly,polylineTimer;

        //     if(vm.trSection.assets[0].assetCoordinates.length){
        //         locations.map.setCenter({lat:vm.trSection.assets[0].assetCoordinates[0].latitude,lng:
        //             vm.trSection.assets[0].assetCoordinates[0].longitude
        //         })
        //     }

        //     vm.trSection.assets.forEach(function (each){
        //         var tempCoordinates = []
        //         var distance = each.assetLength ? "<br><b> Distance </b><br>" + (each.assetLength).toFixed(2) + " km" : "";

        //         var blockPolyLine = each.block ? "<br><b> Block </b><br>" + each.block  : "";
        //         if(each.assetCoordinates.length){
        //             each.assetCoordinates.sort(function(a, b) { return a.sortOrder - b.sortOrder});

        //             each.assetCoordinates.forEach(function (item){
        //                 tempCoordinates.push({lat:item.latitude,lng:item.longitude})
        //             })
        //         }
        //         polylineSection = new google.maps.Polyline({
        //             path: tempCoordinates,
        //             geodesic: true,
        //             strokeColor: each.assetColor,
        //             strokeOpacity: 0.4,
        //             strokeWeight: 6,
        //         });

        //         google.maps.event.addListener(
        //             polylineSection,
        //             "click",
        //             function (event) {
        //                 if (openedInfoWindow != null) {
        //                     openedInfoWindow.close();
        //                     if (spreadPoly != null) {
        //                         spreadPoly.setMap(null);
        //                     }
        //                 }

        //                 clearTimeout(polylineTimer);

        //                 spreadPoly = new google.maps.Polyline({
        //                     path: tempCoordinates,
        //                     strokeColor: "#A938F3",
        //                     strokeOpacity: 0.6,
        //                     strokeWeight: 19,
        //                 });

        //                 spreadPoly.setMap(locations.map);

        //                 polylineTimer = setTimeout(function () {
        //                     if (spreadPoly != null) {
        //                         spreadPoly.setMap(null);
        //                         spreadPoly = null;
        //                     }
        //                 }, 5000);

        //                 var userContent = document.createElement(
        //                     "div"
        //                 ),
        //                     btnEditAsset,
        //                     brBreak, btnDeleteAsset,btnCreateSection,btnCommonPath;

        //                 userContent.innerHTML =
        //                     '<div class="infoWindowhead">' +
        //                     "<b> Name </b><br>" +
        //                     each.name +  distance + blockPolyLine
        //                     /* "<br><b>Latitude : </b>" + event.latLng.lat() +
        //                     "<br><b>Longitude : </b>" + event.latLng.lng() + */
        //                     "</br></div>";

        //                 infowindow.setContent(userContent);

        //                 infowindow.open(locations.map);
        //                 infowindow.setPosition(event.latLng);
        //                 openedInfoWindow = infowindow;
        //             }
        //         );

        //         polylineSection.setMap(locations.map);
        //     })
        //  } 

    }
})();
