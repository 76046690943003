(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("TaskTypeDialogController", TaskTypeDialogController);

    TaskTypeDialogController.$inject = [
        "$timeout",
        "$scope",
        "$stateParams",
        "$state",
        "entity",
        "TaskType",
        "User",
        "TaskTypeSearch",
        "AllCustomTaskTypeAttribute",
        "Configs"
    ];

    
    function TaskTypeDialogController(
        $timeout,
        $scope,
        $stateParams,
        $state,
        entity,
        TaskType,
        User,
        TaskTypeSearch,
        AllCustomTaskTypeAttribute,
        Configs
    ) {
        var vm = this;
        //tasktype attribute types
        $scope.attributetypes = ["INTEGER", "FLOAT", "STRING", "BOOLEAN"];
        //end
        vm.taskType = entity;
        vm.taskTypeImageName = taskTypeImageName;
        vm.decodeImage = decodeImage;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.customTaskTypeAttributes = loadPage();
        vm.customAttributesMap = {};
        $scope.allimages = false;
        $scope.toggle = function () {
            $scope.allimages = !$scope.allimages;
        };
        var userAdminAuthorities = []
        
        vm.authorities = []
        vm.save = save;
        $(":file").filestyle({ buttonBefore: true });
        $(":file").filestyle("buttonText", "Browse File");
        vm.checkNumber;
        $(".select2").select2()
        vm.multiAuthorities = [];
        vm.storeConfig = {}
        config("ROLLOUT_TASK_CATEGORY")
        config("ROLLOUT_TASK_STAGE")
        function config(catName){
            Configs.get(catName, function (response) {
                if(response.length){
                    vm.storeConfig[catName] = [];
                    response.forEach(function (item){
                        vm.storeConfig[catName].push({key:item.configKey, value:item.configValue});
                    })  
                }
            });
        }

        function taskTypeImageName(image) {
            vm.taskType.image = image;
        }
        
       
        function decodeImage(img) {
            return window.atob(img);
        }

        function loadPage() {
            var selectedAuthorities = [];
            AllCustomTaskTypeAttribute.getAll(function (response) {
                vm.customTaskTypeAttributes = response;
                response.forEach(function (arrayItem) {
                    vm.customAttributesMap[arrayItem.name] =
                        arrayItem.customTaskTypeAttributeValues;
                });
            });

            if(vm.taskType.id != null){
                vm.taskType.authorities.forEach(function (authority) {
                    selectedAuthorities.push(authority);
                });
            }

            Configs.get("Roles",function(response){
                if(response.length){
                    response.forEach(function(item){
                        userAdminAuthorities.push(item.configKey)
                    })
    
                    userAdminAuthorities.forEach(function(authority){
                        if (selectedAuthorities.indexOf(authority) !== -1) {
                            vm.multiAuthorities.push({ name: authority, ticked: true });
                        } else {
                            vm.multiAuthorities.push({ name: authority, ticked: false });
                        }
                    });
                }
            })
        }

        //      vm.users = User.query();
        vm.items = [{ name: "", attributeDataType: {}, value: "" }];

        if (
            vm.taskType.taskTypeAttribute != null &&
            vm.taskType.taskTypeAttribute.length != 0
        ) {
            vm.items = vm.taskType.taskTypeAttribute;
        }
        var tempItems = [];
        vm.items.forEach(function (value) {
            if (value.taskCategory == null) {
                value.taskCategory = "DEFAULT";
            }
            tempItems.push(value);
        });

        vm.items = tempItems;
      /*   $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        }); */

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }
        function save() {
            vm.taskType.authorities = [];
            vm.authorities.forEach(function(auth){
                vm.taskType.authorities.push(auth.name);
            });
            vm.isSaving = true;
            if (vm.taskType.id !== null) {
                vm.taskType.taskTypeAttribute = [];
                for (var i = 0; i < vm.items.length; i++) {
                    var attributes = { name: vm.items[i] };
                    vm.taskType.taskTypeAttribute.push({
                        taskCategory: vm.items[i].taskCategory,
                        id: vm.items[i].id,
                        mandatory: vm.items[i].mandatory,
                        name: vm.items[i].name,
                        attributeDataType: vm.items[i].attributeDataType,
                        value: vm.items[i].value,
                        taskTypeAttribOrder: vm.items[i].taskTypeAttribOrder
                    });
                }
                TaskType.update(vm.taskType, onSaveSuccess, onSaveError);
            } else {
                vm.taskType.taskTypeAttribute = [];

                for (var i = 0; i < vm.items.length; i++) {
                    var attributes = { name: vm.items[i] };
                    vm.taskType.taskTypeAttribute.push({
                        taskCategory: vm.items[i].taskCategory,
                        id: vm.items[i].id,
                        mandatory: vm.items[i].mandatory,
                        name: vm.items[i].name,
                        attributeDataType: vm.items[i].attributeDataType,
                        value: vm.items[i].value,
                        taskTypeAttribOrder: vm.items[i].taskTypeAttribOrder
                    });
                }
                TaskType.save(vm.taskType, onSaveSuccess, onSaveError);
            }
        }

        $("#taskTypeImage").change(function () {
            var filesSelected = document.getElementById("taskTypeImage").files;
            for (var i = 0; i < filesSelected.length; i++) {
                var fileReader = new FileReader();
                fileReader.onload = function (fileLoadedEvent) {
                    var test = window.btoa(fileLoadedEvent.target.result);
                    vm.taskType.image = test;
                };
                fileReader.readAsBinaryString(filesSelected[i]);
            }
        });

        function onSaveSuccess(result) {
            $scope.$emit("trakeyeApp:taskTypeUpdate", result);
            vm.isSaving = false;

            var pageValue = sessionStorage.getItem("prevPage");
            $state.go("task-type");
        }

        function onSaveError() {
            vm.isSaving = false;
        }


        vm.addmore = function (index) {

            
            //vm.items.push({name: '',attributeDataType:{}, value:''});
            vm.items.splice((index+1), 0, {
                name: "",
                attributeDataType: {},
                value: "",
            });
           
        };

        vm.remove = function (index) {
            vm.items.splice(index, 1);
           
           
        };

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.updateDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        

       
    }
})();
