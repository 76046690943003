(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("TrTaskDialogController", TrTaskDialogController);

    TrTaskDialogController.$inject = [
        "$timeout",
        "$scope",
        "$state",
        "entity",
        "TrTask",
        "AllTaskTypes",
        "AllCustomTaskTypeAttribute",
        "TaskAttributeMappingId",
        "Configs",
        "RouteTypeSearch",
        "ActivatedUserSearch",
        "$window",
        "AlertService",
        "$rootScope",
        "TaskSectionById",
        "TaskRouteById",
        "TrUserTask",
        "AllVendorIds",
        "UsersByVendorId",
        "route",
        "section",
        "TaskPathById"
    ];

    function TrTaskDialogController(
        $timeout,
        $scope,
        $state,
        entity,
        TrTask,
        AllTaskTypes,
        AllCustomTaskTypeAttribute,
        TaskAttributeMappingId,
        Configs,
        RouteTypeSearch,
        ActivatedUserSearch,
        $window,
        AlertService,
        $rootScope,
        TaskSectionById,
        TaskRouteById,
        TrUserTask,
        AllVendorIds,
        UsersByVendorId,
        route,
        section,
        TaskPathById
    ) {
        var vm = this;
        vm.trTask = entity;
        vm.save = save;
        var entity_old = angular.copy(vm.trTask);
        vm.selectAttributes = selectedTaskTypeAttributes;
        vm.taskType = entity.taskType;
        vm.filterActivatedUser = filterActivatedUser;
        var usersMap = {};
        vm.findUsers = findUsers;

        var infowindow = new google.maps.InfoWindow();
        var openedInfoWindow = null;
        var btnAddSection,btnAddAsset,spreadPoly;
        var hexColors = [
            "#FF5733",
            "#45B39D",
            "#9B59B6",
            "#F4D03F",
            "#3498DB",
            "#E74C3C",
            "#2ECC71",
            "#1ABC9C",
            "#D35400",
            "#8E44AD",
            "#27AE60",
            "#2980B9",
            "#C0392B",
            "#16A085",
            "#9B59B6",
            "#7F8C8D",
            "#F39C12",
        ];
        

        if ($state.params.routeId) {
            vm.trTask.routeId = parseInt($state.params.routeId, 10);
        }

        if ($state.params.sectionId && $state.params.routeId) {
            vm.trTask.sectionId = parseInt($state.params.sectionId, 10);
            vm.trTask.routeId =  parseInt($state.params.routeId, 10);; // If section Id is available task is created using Section
        }

        vm.customAttributesMap = {};
        vm.customAttributesMapp = [];
        loadPage();
        var markersSection = []

        if (!vm.trTask.id) {
            vm.trTask.status = "NEW"
            vm.trTask.asset = []
        }

        Configs.get("RolloutTaskStatus", function (response) {
            vm.rolloutTaskStatus = response;
        });


        AllVendorIds.getAll(function (response) {
            if (response.length) {
                vm.vendorList = response.sort(function (a, b) {
                    var nameA = a.name.toUpperCase();
                    var nameB = b.name.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0; // Names are equal
                });
            }
        });

        function mapStyles() {
            var styles;
            return (styles = [
                {
                    featureType: "poi",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    elementType: "labels.text.fill",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                } /* 
                {
                    elementType: "geometry.fill",
                    stylers: [
                        {
                            color: "#EFEFEF",
                        },
                    ],
                }, */,
                {
                    featureType: "transit",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    featureType: "road",
                    elementType: "labels.icon",
                    stylers: [{ visibility: "off" }],
                },
                {
                    featureType: "landscape",
                    stylers: [
                        {
                            color: "#efefef",
                        },
                    ],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry",
                    stylers: [
                        {
                            color: "#ffffff",
                        },
                    ],
                },
            ]);
        }

        if (vm.trTask.id != null) {
            vm.trTaskType = vm.trTask.taskType;
        }

        function loadPage() {

            vm.imageInNewTab = function (img) {
                var imgWindow = window.open();
                imgWindow.document.write(
                    '<img width="25%"   src="data:image/jpeg;base64,' +
                    img +
                    '">'
                );
                imgWindow.document.close();
            };

            var taskTypeAttributeValuesList =
                vm.trTask.taskTypeAttributeValues;

            try {
                for (var index in vm.trTask.taskType.taskTypeAttributes) {
                    if (
                        !checkIfTaskTypeExists(
                            vm.trTask.taskType.taskTypeAttributes[index],
                            vm.trTask.taskTypeAttributeValues
                        )
                    ) {
                        var taskTypeAttribute1 =
                            vm.trTask.taskType.taskTypeAttributes[index];
                        taskTypeAttributeValuesList.push({
                            taskTypeAttribute: taskTypeAttribute1,
                            attributeValue:
                                vm.trTask.taskType.taskTypeAttributes[index]
                                    .defaultValue,
                        });
                    }
                }
            } catch (e) { }

            function checkIfTaskTypeExists(taskType, taskTypeAttrVal) {
                var hasTaskType = false;
                for (var i = 0; i < taskTypeAttrVal.length; i++) {
                    var name = taskTypeAttrVal[i].taskTypeAttribute.name;
                    if (name == taskType.name) {
                        hasTaskType = true;
                        break;
                    }
                }
                return hasTaskType;
            }
            AllTaskTypes.getAll(function (response) {
                vm.taskTypes = response;
            });

            AllCustomTaskTypeAttribute.getAll(function (response) {
                //vm.customTaskTypeAttributes = response;
                response.forEach(function (arrayItem) {
                    if (arrayItem.customTaskTypeAttributeValues != undefined) {
                        vm.customAttributesMap[arrayItem.name] =
                            arrayItem.customTaskTypeAttributeValues;
                        vm.customAttributesMap[arrayItem.name].parentId =
                            arrayItem.id;
                    }

                });
                if (vm.trTask.id) {
                    $(":file").filestyle({
                        buttonBefore: true,
                    });
                    $(":file").filestyle("buttonText", "Browse File");

                    TaskPathById.get(vm.trTask.id, function (response) {
                        drawPolygon(vm.trTask.id, response, {}, 10, 1, "task", false, 1);
                        if (response.COORDINATE != undefined) {
                            if (response.COORDINATE.length > 0) {
                                locations.map.setCenter({ lat: response.COORDINATE[0].latitude, lng: response.COORDINATE[0].longitude });
                            }
                        }
                    });
                }
            });


        }
        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function selectedTaskTypeAttributes() {
            vm.showFilter = true;
            if (vm.taskType) {
                vm.trTask.taskType = vm.taskType;
                if (
                    entity_old.taskType &&
                    vm.taskType.id === entity_old.taskType.id
                ) {
                    vm.trTask.taskTypeAttributeValues = [];
                    vm.trTask.taskTypeAttributeValues =
                        entity_old.taskTypeAttributeValues;
                } else {
                    vm.trTask.taskTypeAttributeValues = [];
                    $.each(
                        vm.taskType.taskTypeAttribute,
                        function (key, value) {
                            vm.trTask.taskTypeAttributeValues.push({
                                taskTypeAttribute:
                                    vm.taskType.taskTypeAttribute[key],
                            });
                        }
                    );
                }
            }
        }

        function filterActivatedUser() {
            if (vm.trTask.assignedToUser != null && vm.trTask.assignedToUser != "" && !angular.isUndefined(vm.trTask.assignedToUser)) {
                ActivatedUserSearch.query({
                    userId: vm.trTask.assignedToUser,
                }, onSuccess, onError);

                function onSuccess(data, headers) {
                    vm.activatedusers = [];
                    data.forEach(function (dataVal) {
                        vm.activatedusers.push(dataVal);
                    });
                    $scope.showResults = true;

                    angular.forEach(vm.activatedusers, function (value, key) {
                        usersMap[value.login] = value.id;
                    });
                }

                function onError(error) {
                    AlertService.error(error.data.message);
                }
            }
        }

        $scope.assignUser = function (user) {
            // Implement the logic to assign the selected user
            vm.trTask.assignedToUser = user
            $scope.showResults = false;
            vm.assignToModified = true
        };


        function save() {
            var spreadAV = vm.trTask.taskTypeAttributeValues;
            for (
                var spreadAssetIndex = 0;
                spreadAssetIndex < spreadAV.length;
                spreadAssetIndex++
            ) {
                if (
                    spreadAV[spreadAssetIndex].taskTypeAttribute
                        .name === "Fiber Distance (km)"
                ) {
                    spreadAV[
                        spreadAssetIndex
                    ].attributeValue = fiberDistance;
                }
            }
            vm.trTask.taskTypeAttributeValues = spreadAV;
            var assignedToUser = $("#field_assignedToUser").val();
            vm.trTask.assignedToUser = assignedToUser;

            callTask()
        }

        function callTask() {
            if (markers.length == 2 || (vm.trTask.fromRoutePoint==null && vm.trTask.toRoutePoint==null)) {
                markers.forEach(function (item) {
                    if (item.label.text == labelFrom || item.label.text == labelTo) {
                        if (item.label.text == labelFrom) {
                            if (vm.trTask.id) {
                                vm.trTask.fromRoutePoint.latitude = item.position.lat()
                                vm.trTask.fromRoutePoint.longitude = item.position.lng()
                            } else {
                                vm.trTask.fromRoutePoint = {
                                    "latitude": item.position.lat(), "longitude": item.position.lng()
                                };
                            }
                        }

                        if (item.label.text == labelTo) {
                            if (vm.trTask.id) {
                                vm.trTask.toRoutePoint.latitude = item.position.lat()
                                vm.trTask.toRoutePoint.longitude = item.position.lng()
                            } else {
                                vm.trTask.toRoutePoint = {
                                    "latitude": item.position.lat(), "longitude": item.position.lng()
                                };
                            }
                        }
                    }
                })
                if (vm.trTask.id) {
                    if (vm.trTask.verified) {
                        vm.trTask.approvedByUserId = $rootScope.loginUserId
                    }

                    /*  if(vm.assignToModified){
                         var projectId,routeId,sectionId
                         if(vm.trTask.sectionTask.length){
                             projectId = vm.trTask.sectionTask[0].projectId;
                             routeId = vm.trTask.sectionTask[0].routeId;
                             sectionId = vm.trTask.sectionTask[0].sectionId;
 
                         }else if(vm.trTask.routeTask.length){
                             projectId = vm.trTask.routeTask[0].projectId;
                             routeId = vm.trTask.routeTask[0].routeId;
                             sectionId = vm.trTask.routeTask[0].sectionId;
                         }
                         var data = {
                             "id": null,
                             "task":{
                                 "id": vm.trTask.id
                             
                             },
                           "userId": $rootScope.loginUserId,
                           "tenantId": vm.trTask.tenantId,
                             "project":{
                                 "id": projectId
                             
                             },
                             "route":{
                                 "id": routeId
                             
                             },
                             "section":{
                                 "id": sectionId
                             
                             }
                         }
                         TrUserTask.update(data)
                     } */
                    TrTask.update(vm.trTask, onSaveSuccess, onSaveError)
                } else {
                    TrTask.save(vm.trTask, onSaveSuccess, onSaveError)
                }
            } else {
                console.log("Task From or To Points are Empty")
            }
        }

            
        function onSaveSuccess(result) {
            $window.history.back();
        }

        function onSaveError() {
        }


        Configs.get("WorkFlowStatus", function (response) {
            vm.workFlowStatus = [];
            var workFlowStatusList = response[0].configValue.split(",");
            workFlowStatusList.forEach(function (value) {
                if (value.length > 0) {
                    vm.workFlowStatus.push({ "configKey": value, "configValue": value });
                }
            });
        });

        Configs.get("Phases", function (response) {
            vm.workFlowPhase = response;
            //vm.workFlow.phase = "Planning Phase"
            /*  if(vm.workFlow.id == null){
                response.forEach(function(val){
                    if(val.configValue == '01'){
                        vm.workFlow.phase = val.configKey
                    }
                })
            }*/
        });


        /*  ActivatedUserSearch.query(
             {
                 // userId: vm.workFlow.assignedToUser,
                 userId: vm.workFlow.groups[0].id,
                 user: vm.user,
             },
             onSuccess,
             onError
         );
 
         function onSuccess(data, headers) {
             data.forEach(function (users) {
                 vm.assignedUsers.push(users.login);
             });
             vm.activatedusers = data;
 
             angular.forEach(vm.activatedusers, function (value, key) {
                 usersMap[value.login] = value.id;
             });
         }
         function onError(error) {
             AlertService.error(error.data.message);
         } */

        /*  RouteTypeSearch.query({userId:$scope.search,
             page: pagingParams.page - 1,
             size: vm.itemsPerPage,
             sort: sort()
         }, onSuccess, onError);
         function sort() {
             var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
             if (vm.predicate !== 'id') {
               //  result.push('id');
             }
             return result;
         }
         function onSuccess(data, headers) {
             vm.links = ParseLinks.parse(headers('link'));
             vm.totalItems = headers('X-Total-Count');
             vm.queryCount = vm.totalItems;
             vm.routeTypes = data;
             vm.page = pagingParams.page;
     
         }
         function onError(error) {
             AlertService.error(error.data.message);
         } */

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }


        //Task Map 
        var markers = []
        var coordinateList = []
        var chainageList = []
        var markerList = []
        var iconSize = 20
        var labelFrom, labelTo
        labelFrom = 'A'
        labelTo = 'B'
        var locations = {};

        locations.map = new google.maps.Map(
            document.getElementById("map_canvasStart"),
            {
                center: {
                    lat: parseFloat($rootScope.DEFAULT_LATITUDE),
                    lng: parseFloat($rootScope.DEFAULT_LONGITUDE),
                },
                zoom: $rootScope.rolloutMapInitZoom,
                fullscreenControl: true,
                styles: mapStyles(),
            }
            //myOption(16, vm.getCenterStart)
        );

        vm.removeMarker = function () {
            markers.forEach(function (marker) {
                marker.setMap(null);
            });

            // Clear the markers array
            markers = [];

            if(spreadPoly){
            spreadPoly.setMap(null);
            spreadPoly = null;
            }
        }

        function addClick(item) {
            // Add a click event listener to the map
            item.addListener("click", function (event) {
                if (markers.length < 2) {
                    // Add a marker to the map
                    addMarker(event.latLng);

                    // Get the latitude and longitude of the clicked point
                    vm.pinLat = event.latLng.lat();
                    vm.pinLong = event.latLng.lng();
                } else {
                    console.log("Maximum 2 markers allowed.");
                }
            });
        }


        function createMarkers(arr, img, map) {
            arr.forEach(function (each) {
                var marker = new google.maps.Marker({
                    position: { lat: each.lat, lng: each.lng },
                    map: map,
                    icon: {
                        scaledSize: new google.maps.Size(iconSize, iconSize),
                        url: img,
                        anchor: new google.maps.Point(
                            iconSize / 2,
                            iconSize / 2
                        ),
                        labelOrigin: new google.maps.Point(25, 22),
                    },
                });

                google.maps.event.addListener(
                    marker,
                    "click",
                    (function () {
                        //var name = (each.name == (undefined || null)) ? " " : each.name
                        if (openedInfoWindow != null) {
                            openedInfoWindow.close();
                        }
                        var userContent = document.createElement("div")

                        userContent.innerHTML = '<div class="infoWindowhead"></br></br>' +
                            /* "<b>Name : "+name+"</b></br>"+ */
                            "<b>Latitude : " + each.lat + "</b></br>" +
                            "<b>Longitude : " + each.lng + "</b></br>" +
                            "<b>Type : " + each.type + "</b></br>" +
                            "<b>Number : " + each.number + "</b></br>" +
                            "</br></div>"
                        infowindow.setContent(
                            userContent
                        );

                        infowindow.open(map);
                        infowindow.setPosition({
                            lat: each.lat,
                            lng: each.lng,
                        });
                        openedInfoWindow = infowindow;

                        if (markers.length < 2) {
                            btnAddSection = userContent.appendChild(
                                document.createElement("input")
                            );
                            btnAddSection.type = "button";

                            btnAddSection.value = "Add Task Point";

                            btnAddSection.className = "button";

                            btnAddSection.addEventListener("click", function () {
                                if (markers.length < 2) {
                                    // Add a marker to the map
                                    addMarker({
                                        lat: each.lat,
                                        lng: each.lng,
                                    });

                                    // Get the latitude and longitude of the clicked point
                                    vm.pinLat = each.lat;
                                    vm.pinLong = each.lng;
                                    openedInfoWindow.close();
                                } else {
                                    alert("From and To Task Exists");
                                }
                            })
                        }
                    }))

                //addClick(marker)
                arr.push(marker);
            })
        }

        function addMarker(location) {
            var markerText
            if (markers.length == 0) {
                markerText = labelFrom
            } else if (markers.length == 1) {
                markerText = labelTo
            }
            // Create a marker
            var marker = new google.maps.Marker({
                position: location,
                map: locations.map,
                label: {
                    text: markerText,
                },
                draggable: true
            });

            // Add the marker to the markers array
            markers.push(marker);
        }

        if (vm.trTask.sectionId != null) {
            /* TaskSectionById.get(vm.trTask.sectionId, function (sectionVal) {
                showPathOnMap(sectionVal, coordinateList, chainageList, locations, addClick, createMarkers);
            }); */
        }

        if (vm.trTask.sectionId == null && vm.trTask.routeId != null) {
            TaskRouteById.get(vm.trTask.routeId, function (routeVal) {
                showPathOnMap(routeVal, coordinateList, chainageList, locations, addClick, createMarkers);
            });
        }

        if(route && route.assets.length){
            route.assets.forEach(function (element){
                locations.map.setCenter({lat:element.assetCoordinates[0].latitude, lng:
                    element.assetCoordinates[0].longitude} );
                    
                 
                        
                        if(element.assetCoordinates.length){

                            if(element.assetType.layout == "SPREAD"){
                                vm.tempPath = []
                            element.assetCoordinates.forEach(function (item){
                                vm.tempPath.push({lat:item.latitude,lng:item.longitude})
                            })
                            var polyline = new google.maps.Polyline({
                                path: vm.tempPath,
                                geodesic: true,
                                strokeColor: element.assetType.colorcode,
                                strokeOpacity: 0.6,
                                strokeWeight: 4,
                            });
                            addClick(polyline);
                            // Set the map to display the polyline
                            polyline.setMap(locations.map);
                            }

                            if(element.assetType.layout == "FIXED"){
                                element.assetCoordinates.forEach(function (e) {
                                    var marker = new google.maps.Marker({
                                    position:  {lat:e.latitude,lng:e.longitude},
                                    map: locations.map,
                                    icon: {
                                        scaledSize: new google.maps.Size(iconSize, iconSize),
                                        url: element.assetType.imagePath,
                                        anchor: new google.maps.Point(
                                            iconSize / 2,
                                            iconSize / 2
                                        ),
                                        labelOrigin: new google.maps.Point(25, 22),
                                    },
                                });
                                google.maps.event.addListener(
                                    marker,
                                    "click",
                                    (function () {
                                        //var name = (each.name == (undefined || null)) ? " " : each.name
                                        if (openedInfoWindow != null) {
                                            openedInfoWindow.close();
                                        }
                                        var userContent = document.createElement("div")
                
                                        userContent.innerHTML = '<div class="infoWindowhead"></br></br>' +
                                        /* "<b>Name : "+name+"</b></br>"+ */
                                        "<b>Latitude : "+e.latitude+"</b></br>"+
                                        "<b>Longitude : "+e.longitude+"</b></br>"+
                                        "</br></div>"
                                        infowindow.setContent(
                                            userContent
                                        );
                
                                        infowindow.open(locations.map);
                                        infowindow.setPosition({
                                            lat: e.latitude,
                                            lng: e.longitude,
                                        });
                                        openedInfoWindow = infowindow;
                
                                        if (markers.length < 2) {
                                            btnAddSection = userContent.appendChild(
                                                document.createElement("input")
                                            );
                                            btnAddSection.type = "button";
                
                                            btnAddSection.value = "Add Task Point";
                                            
                                            btnAddSection.className = "button";
                
                                            btnAddSection.addEventListener("click", function () {
                                                if (markers.length < 2) {
                                                    // Add a marker to the map
                                                    addMarker({
                                                        lat: e.latitude,
                                                        lng: e.longitude,
                                                    });
                                    
                                                    // Get the latitude and longitude of the clicked point
                                                    vm.pinLat = e.latitude;
                                                    vm.pinLong = e.longitude;
                                                    openedInfoWindow.close();
                                                } else {
                                                    alert("From and To Task Exists");
                                                }
                                               })
                                         }
                                     }))
                               
                
                                })
                              }
                            }
                            
                        })
               
        }

        function showPathOnMap(sectionVal, coordinateList, chainageList, locations, addClick, createMarkers) {
            if (sectionVal.COORDINATE != null) {
                sectionVal.COORDINATE.forEach(function (coor) {
                    coordinateList.push({ lat: coor.latitude, lng: coor.longitude });
                });
            }

            if (sectionVal.CHAINAGE != null) {
                sectionVal.CHAINAGE.forEach(function (chainageVal) {
                    chainageList.push({ lat: chainageVal.latitude, lng: chainageVal.longitude, type: chainageVal.routePointType, number: chainageVal.routePointNumber });
                });
            }

            if (coordinateList.length) {
                locations.map.setCenter(coordinateList[0]);
                var polyline = new google.maps.Polyline({
                    path: coordinateList,
                    geodesic: true,
                    strokeColor: '#FD7A24',
                    strokeOpacity: 1.0,
                    strokeWeight: 4,
                });

                // Set the map to display the polyline
                polyline.setMap(locations.map);
                addClick(polyline);
            }

            if (chainageList.length) {
                locations.map.setCenter(chainageList[0]);
                createMarkers(chainageList, 'content/images/chainagePoints.png', locations.map);
            }
        }


        if (section && section.id && section.fromRoutePoint && section.toRoutePoint) {
             addMarkerSection({ lat: section.fromRoutePoint.latitude, lng: section.fromRoutePoint.longitude }, "From Section")
             addMarkerSection({ lat: section.toRoutePoint.latitude, lng: section.toRoutePoint.longitude }, "To Section")
             locations.map.setCenter({ lat: section.fromRoutePoint.latitude, lng: section.fromRoutePoint.longitude });
         }

        if (vm.trTask && vm.trTask.id && vm.trTask.fromRoutePoint && vm.trTask.toRoutePoint) {
            addMarker({ lat: vm.trTask.fromRoutePoint.latitude, lng: vm.trTask.fromRoutePoint.longitude })
            addMarker({ lat: vm.trTask.toRoutePoint.latitude, lng: vm.trTask.toRoutePoint.longitude })
            locations.map.setCenter({ lat: vm.trTask.fromRoutePoint.latitude, lng: vm.trTask.fromRoutePoint.longitude });
        }

        function findUsers(){
            UsersByVendorId.get(vm.selectedVendor,function(response) {
                vm.userList = [];
                response.forEach(function(user){
                    var userAuth = user.authorities[0];
                    userAuth = userAuth.replace('ROLE_','');
                    if(userAuth == 'PLAN_SURVEY'){
                        vm.userList.push({name:user.login +" - "+ userAuth, id:user.id});
                    }
                    
                });
			});
        }

        function addMarkerSection(location, text) {
            // Create a marker
            var marker = new google.maps.Marker({
                position: location,
                map: locations.map,
                icon: {
                    scaledSize: new google.maps.Size(iconSize, iconSize),
                    url: "content/images/sections.png",
                    anchor: new google.maps.Point(
                        iconSize / 2,
                        -4
                    ),
                    labelOrigin: new google.maps.Point(25, 22),
                },
                label: {
                    text: text,
                }
            });

            // Add the marker to the markers array
            markersSection.push(marker);
        }

        vm.objectRouteImg = {}
        vm.objectRouteImg.routeId = $state.params.routeId
        vm.objectRouteImg.map = locations.map

        $('.select2').select2();

        geoLocation(locations.map);

        function geoLocation(map) {
            var goTo = document.createElement("span");
            goTo.setAttribute("title", "Location Coordinates");
            goTo.classList.add("custom-map-control-button");
            var gotoIcon = document.createElement("i");
            gotoIcon.classList.add("fa");
            gotoIcon.classList.add("fa-location-arrow");
            gotoIcon.classList.add("geo");
            gotoIcon.classList.add("mt-5");
            goTo.appendChild(gotoIcon);
            map.controls[google.maps.ControlPosition.RIGHT_TOP].push(goTo);
            goTo.addEventListener("click", function () {
                vm.removeMarker()
                $("#modalLatLongs").show();
            });
        }

        vm.closeModal = function (id) {
            vm.getLocationCoordinateA = null
            vm.getLocationCoordinateB = null

            $("#" + id).removeClass("in");
            $(".modal-backdrop").remove();
            $("#" + id).hide();
        }

        vm.onLocationCoordinates = function () {
            var latlngA = vm.getLocationCoordinateA.split(",");
            var latlngB = vm.getLocationCoordinateB.split(",");

            addMarker({ lat: parseFloat(latlngA[0]), lng: parseFloat(latlngA[1]) })
            addMarker({ lat: parseFloat(latlngB[0]), lng: parseFloat(latlngB[1]) })

            locations.map.setCenter({ lat: parseFloat(latlngB[0]), lng: parseFloat(latlngB[1]) })
            vm.closeModal('modalLatLongs')
        }

     

     /*  if(section && section.assets.length){
            section.assets.forEach(function (element){
                        if(element.assetCoordinates.length){
                            locations.map.setCenter({lat:element.assetCoordinates[0].latitude, lng:
                                element.assetCoordinates[0].longitude} );
                            if(element.assetType.layout == "SPREAD"){
                                vm.tempPath = []
                                element.assetCoordinates.sort(function(a, b) { return a.sortOrder - b.sortOrder});
                            element.assetCoordinates.forEach(function (item){
                                vm.tempPath.push({lat:item.latitude,lng:item.longitude})
                            })
                            var polyline = new google.maps.Polyline({
                                path: vm.tempPath,
                                geodesic: true,
                                strokeColor: element.assetType.colorcode,
                                strokeOpacity: 0.6,
                                strokeWeight: 4,
                            });
                            addClick(polyline);
                            // Set the map to display the polyline
                            polyline.setMap(locations.map);
                            }

                            if(element.assetType.layout == "FIXED"){
                                element.assetCoordinates.forEach(function (e) {
                                    var marker = new google.maps.Marker({
                                    position:  {lat:e.latitude,lng:e.longitude},
                                    map: locations.map,
                                    icon: {
                                        scaledSize: new google.maps.Size(iconSize, iconSize),
                                        url: element.assetType.imagePath,
                                        anchor: new google.maps.Point(
                                            iconSize / 2,
                                            iconSize / 2
                                        ),
                                        labelOrigin: new google.maps.Point(25, 22),
                                    },
                                });
                                google.maps.event.addListener(
                                    marker,
                                    "click",
                                    (function () {
                                        //var name = (each.name == (undefined || null)) ? " " : each.name
                                        if (openedInfoWindow != null) {
                                            openedInfoWindow.close();
                                        }
                                        var userContent = document.createElement("div")
                
                                        userContent.innerHTML = '<div class="infoWindowhead"></br></br>' +
                                         //"<b>Name : "+name+"</b></br>"+ 
                                        "<b>Latitude : "+e.latitude+"</b></br>"+
                                        "<b>Longitude : "+e.longitude+"</b></br>"+
                                        "</br></div>"
                                        infowindow.setContent(
                                            userContent
                                        );
                
                                        infowindow.open(locations.map);
                                        infowindow.setPosition({
                                            lat: e.latitude,
                                            lng: e.longitude,
                                        });
                                        openedInfoWindow = infowindow;
                
                                        if (markers.length < 2) {
                                            btnAddSection = userContent.appendChild(
                                                document.createElement("input")
                                            );
                                            btnAddSection.type = "button";
                
                                            btnAddSection.value = "Add Section Point";
                                            
                                            btnAddSection.className = "button";
                
                                            btnAddSection.addEventListener("click", function () {
                                                if (markers.length < 2) {
                                                    // Add a marker to the map
                                                    addMarker({
                                                        lat: e.latitude,
                                                        lng: e.longitude,
                                                    });
                                    
                                                    // Get the latitude and longitude of the clicked point
                                                    vm.pinLat = e.latitude;
                                                    vm.pinLong = e.longitude;
                                                    openedInfoWindow.close();
                                                } else {
                                                    alert("From and To Task Exists");
                                                }
                                               })
                                         }
                                     }))
                               
                
                                })
                              }
                            }
                            
                        })
               
        }  */

                        if(section.assets && section.assets.length && !vm.trTask.id){
                            var polylineSection,polylineTimer;
                
                            if(section.assets[0].assetCoordinates.length){
                                locations.map.setCenter({lat:section.assets[0].assetCoordinates[0].latitude,lng:
                                    section.assets[0].assetCoordinates[0].longitude
                                })
                            }
                            
                            section.assets.forEach(function (each){
                                var tempCoordinates = []
                                var distance = each.assetLength ? "<br><b> Distance </b><br>" + each.assetLength + " mtrs" : "";
                
                                var blockPolyLine = each.block ? "<br><b> Block </b><br>" + each.block  : "";
                                if(each.assetCoordinates.length){
                                    each.assetCoordinates.sort(function(a, b) { return a.sortOrder - b.sortOrder});
                
                                    each.assetCoordinates.forEach(function (item){
                                        tempCoordinates.push({lat:item.latitude,lng:item.longitude})
                                    })
                                }
                                polylineSection = new google.maps.Polyline({
                                    path: tempCoordinates,
                                    geodesic: true,
                                    strokeColor: each.assetColor,
                                    strokeOpacity: 0.4,
                                    strokeWeight: 6,
                                });
                
                                google.maps.event.addListener(
                                    polylineSection,
                                    "click",
                                    function (event) {
                                        if (openedInfoWindow != null) {
                                            openedInfoWindow.close();
                                            if (spreadPoly != null) {
                                                spreadPoly.setMap(null);
                                            }
                                        }
                    
                                        clearTimeout(polylineTimer);
                    
                                        spreadPoly = new google.maps.Polyline({
                                            path: tempCoordinates,
                                            strokeColor: "#A938F3",
                                            strokeOpacity: 0.6,
                                            strokeWeight: 19,
                                        });
                    
                                        spreadPoly.setMap(locations.map); 
                    
                                        /* polylineTimer = setTimeout(function () {
                                            if (spreadPoly != null) {
                                                spreadPoly.setMap(null);
                                                spreadPoly = null;
                                            }
                                        }, 5000); */
                    
                                        var userContent = document.createElement(
                                            "div"
                                        ),
                                            btnEditAsset,
                                            brBreak, btnDeleteAsset,btnCreateSection,btnCommonPath;
                    
                                        userContent.innerHTML =
                                            '<div class="infoWindowhead">' +
                                            "<b> Name </b><br>" +
                                            each.name +  distance + blockPolyLine
                                            /* "<br><b>Latitude : </b>" + event.latLng.lat() +
                                            "<br><b>Longitude : </b>" + event.latLng.lng() + */
                                            "</br></div>";
                                        
                                            
                                        btnAddAsset = userContent.appendChild(
                                            document.createElement("input")
                                        );
                                        btnAddAsset.type = "button";

                                        btnAddAsset.value = "Add To Create Task";

                                        btnAddAsset.className = "button";

                                        btnAddAsset.addEventListener("click", function () {
                                            spreadPoly.setMap(null);
                                                spreadPoly = null;
                                                
                                           vm.trTask.asset = [each.id]

                                           spreadPoly = new google.maps.Polyline({
                                            path: tempCoordinates,
                                            strokeColor: "#A938F3",
                                            strokeOpacity: 0.6,
                                            strokeWeight: 19,
                                            });
                    
                                            spreadPoly.setMap(locations.map);

                                            openedInfoWindow.close();

                                        })
                                            
                                        infowindow.setContent(userContent);
                    
                                        infowindow.open(locations.map);
                                        infowindow.setPosition(event.latLng);
                                        openedInfoWindow = infowindow;
                                    }
                                );
                
                                polylineSection.setMap(locations.map);
                            })
                         } else{
                            google.maps.event.addListener(locations.map, "click", function (event) {
                                if (markers.length < 2) {
                                    // Add a marker to the map
                                    addMarker(event.latLng);
                                } else {
                                    console.log("Maximum 2 markers allowed.");
                                }
                            });
                         }

                         function drawPolygon(id, value, details, zIndex, strokeWeight, type, canClick, zIndexVal) {

                            var verified = details.verified ? "<b>Verified : " + details.verified + "</b></br>" : ""
                            if (value.COORDINATE == undefined) {
                                value = value.value;
                            }
                            if (value == undefined) {
                                return;
                            }
                
                            var latlng = {
                                lat: value.COORDINATE[0].latitude,
                                lng: value.COORDINATE[0].longitude,
                            };
                            // value.COORDINATE.sort(function (a, b) { return a.id - b.id });
                            var path = [];
                            if (value.COORDINATE != null) {
                                value.COORDINATE.forEach(function (location) {
                                    path.push({ lat: location.latitude, lng: location.longitude });
                                });
                            }
                
                            var infowindow = new google.maps.InfoWindow({
                                content:
                                    '<div class="infoWindowhead">' +
                                    "name" +
                                    '<div class="infoWindowContent"> <b>ID :</b> ' +
                                    id +
                                    "</div></div>",
                            });
                
                            infowindow.setPosition(latlng);
                
                            var marker = new google.maps.Marker({
                                position: latlng,
                                map: locations.map,
                                icon: {
                                    scaledSize: new google.maps.Size(20, 20),
                                    url: "content/images/dot_marker.png",
                                    anchor: new google.maps.Point(
                                        iconSize / 2,
                                        iconSize / 2
                                    ),
                                    labelOrigin: new google.maps.Point(25, 22),
                                },
                            });
                            // var strokeColor = "#ff6a08";
                
                            // if (color == "green") {
                            //     strokeColor = "#00e658";
                            // } else if (color == "blue") {
                            //     strokeColor = "#0066CC";
                            // }
                            var strokeOpacity = 1.0;
                            var strokeColor = "#00e658";
                            strokeColor = getRandomString(hexColors);
                            if (type == "task") {
                                //strokeColor = "#0066CC";
                                strokeColor = "#8a0101";
                                strokeOpacity = 0.3;
                            } else if (type == "PROGRESS") {
                                strokeColor = "#00e658";
                            } else if (type == "OBSTACLE") {
                                strokeColor = "#ff0000";
                            } else {
                                strokeColor = "#b5b0b0"
                            }
                            var polyline = new google.maps.Polyline({
                                path: path,
                                geodesic: true,
                                strokeColor: strokeColor,
                                strokeOpacity: strokeOpacity,
                                strokeWeight: 4,
                                zIndex: zIndexVal
                            });
                            var disVal = 0;
                            if (path.length > 0) {
                                for (var index in path) {
                                    if (index > 0) {
                                        disVal += distanceCal(path[index].lat, path[index].lng, path[index - 1].lat, path[index - 1].lng);
                                    }
                                }
                            }
                            // Set the map to display the polyline
                            polyline.setMap(locations.map);
                
                            google.maps.event.addListener(
                                polyline,
                                "mouseout",
                                function (event) {
                                    infowindow.close();
                                }
                            );
                
                
                            if (canClick) {
                                google.maps.event.addListener(
                                    polyline,
                                    "click",
                                    function (event) {
                                        if (openedInfoWindow != null) {
                                            openedInfoWindow.close();
                                        }
                                        infowindow.setContent(
                                            '<div class="infoWindowhead"></br></br>' +
                                            "<b>Date : " + new Date(details.workDate).toString("yyyy-MM-dd HH:mm") + "</b></br>" +
                                            "<b>Length : " + details.workLength + "</b></br>" +
                                            "<b>Hours : " + details.numberOfHours + "</b><br>" +
                                            "<b>Created by : " + details.createdBy + "</b></br>" +
                                            "<b>Type : " + details.taskLogType + "</b></br>" +
                                            verified +
                                            "</br></div>"
                                        );
                
                                        infowindow.mousemove;
                                        infowindow.open(locations.map);
                                        infowindow.setPosition(latlng);
                                        openedInfoWindow = infowindow;
                
                                    }
                                );
                            }
                
                            if (canClick) {
                                google.maps.event.addListener(
                                    polyline,
                                    "click",
                                    function (event) {
                                        if (openedInfoWindow != null) {
                                            openedInfoWindow.close();
                                        }
                                        infowindow.setContent(
                                            '<div class="infoWindowhead"><b>' +
                                            'Task Log'
                                            +
                                            "</b></br></br><b>Date : " + new Date(details.workDate).toString("yyyy-MM-dd HH:mm") + "</b></br>" +
                                            "<b>Length : " + details.workLength + "</b></br>" +
                                            "<b>Hours : " + details.numberOfHours + "</b><br>" +
                                            "<b>Created by : " + details.createdBy + "</b></br>" +
                                            "<b>Type : " + details.taskLogType + "</b></br>" +
                                            verified +
                                            "</br></div>"
                                        );
                
                                        infowindow.mousemove;
                                        infowindow.open(locations.map);
                                        infowindow.setPosition(latlng);
                                        openedInfoWindow = infowindow;
                
                                    }
                                );
                            }
                
                            // tempPolygon.setMap(locations.map);
                            locations.map.setCenter(latlng);
                            // locations.map.setZoom($rootScope.rolloutMapInitZoom);
                            return polyline;
                        }

                        function getRandomString(arr) {
                            var randomIndex = Math.floor(Math.random() * arr.length);
                            return arr[randomIndex];
                        }

                        function distanceCal(lat1, lon1, lat2, lon2) {
                            var theta = lon1 - lon2;
                            var dist = Math.sin(degrees_to_radians(lat1)) * Math.sin(degrees_to_radians(lat2)) +
                                Math.cos(degrees_to_radians(lat1)) * Math.cos(degrees_to_radians(lat2)) * Math.cos(degrees_to_radians(theta));
                            dist = Math.acos(dist);
                            dist = radians_to_degrees(dist);
                            dist = dist * 60 * 1.1515;
                            dist = dist * 1.609344;
                            if (isNaN(dist)) {
                                return 0.0;
                            } else {
                                return (dist);
                            }
                        }

                        function degrees_to_radians(degrees) {
                            var pi = Math.PI;
                            return degrees * (pi / 180);
                        }
                
                        function radians_to_degrees(radians) {
                            var pi = Math.PI;
                            return radians * (180 / pi);
                        }
                         
    }
})();


