(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("TrProjectDialogController", TrProjectDialogController);

    TrProjectDialogController.$inject = [
        "$timeout",
        "$scope",
        "$state",
        "entity",
        "TrProject",
        "AllProjectTypes",
        "AllCustomProjectTypeAttribute",
        "ProjectAttributeMappingId",
        "Configs",
        "pagingParams",
        "AlertService",
        "ParseLinks",
        "$rootScope"
    ];

    function TrProjectDialogController(
        $timeout,
        $scope,
        $state,
        entity,
        TrProject,
        AllProjectTypes,
        AllCustomProjectTypeAttribute,
        ProjectAttributeMappingId,
        Configs,
        pagingParams,
        AlertService,
        ParseLinks,
        $rootScope
    ) {
        var vm = this;
        vm.trProject = entity;
        vm.save = save;
        var entity_old = angular.copy(vm.trProject);
        vm.selectAttributes = selectedProjectTypeAttributes;
        vm.projectType = entity.projectType;
        vm.datePickerOpenStatus = {};
		vm.openCalendar = openCalendar;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.calculateToDate = calculateToDate;
        vm.calculateFromDate = calculateFromDate;
        var locations = {};
        var marker
        var markersStore = []
        var today = new Date();
        
        function openCalendar(date) {
			vm.datePickerOpenStatus[date] = true;
		}
        
		vm.customAttributesMap = {};
		vm.customAttributesMapp = [];
        loadPage();
        if(!vm.trProject.id){
            vm.trProject.status = "NEW"
            vm.trProject.phase = "PLANNING"
            vm.trProject.ragStatus = "GREEN"
            vm.isSaving = true
        }

        function loadPage() {
            vm.imageInNewTab = function (img) {
                var imgWindow = window.open();
                imgWindow.document.write(
                    '<img width="25%"   src="data:image/jpeg;base64,' +
                        img +
                        '">'
                );
                imgWindow.document.close();
            };

            var projectTypeAttributeValuesList =
                vm.trProject.projectTypeAttributeValues;

            try {
                for (var index in vm.trProject.projectType.projectTypeAttribute) {
                    if (
                        !checkIfProjectTypeExists(
                            vm.trProject.projectType.projectTypeAttribute[index],
                            vm.trProject.projectTypeAttributeValues
                        )
                    ) {
                        var projectTypeAttribute1 =
                            vm.trProject.projectType.projectTypeAttribute[index];
                        projectTypeAttributeValuesList.push({
                            projectTypeAttribute: projectTypeAttribute1,
                            attributeValue:
                                vm.trProject.projectType.projectTypeAttribute[index]
                                    .defaultValue,
                        });
                    }
                }
            } catch (e) {}

            function checkIfProjectTypeExists(projectType, projectTypeAttrVal) {
                var hasProjectType = false;
                for (var i = 0; i < projectTypeAttrVal.length; i++) {
                    var name = projectTypeAttrVal[i].projectTypeAttribute.name;
                    if (name == projectType.name) {
                        hasProjectType = true;
                        break;
                    }
                }
                return hasProjectType;
            }

            AllProjectTypes.getAll(function (response) {
                vm.projectTypes = response;
            });

            AllCustomProjectTypeAttribute.getAll(function (response) {
                //vm.customProjectTypeAttributes = response;
                response.forEach(function (arrayItem) {
                    if(arrayItem.customProjectTypeAttributeValues != undefined){
                        vm.customAttributesMap[arrayItem.name] =
                        arrayItem.customProjectTypeAttributeValues;
                        vm.customAttributesMap[arrayItem.name].parentId =
                            arrayItem.id;
                    }
                  
                });
                if (vm.trProject.id) {
                    $(":file").filestyle({
                        buttonBefore: true,
                    });
                    $(":file").filestyle("buttonText", "Browse File");
                }
            });
            
            
        }
        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function selectedProjectTypeAttributes() {
            vm.showFilter = true;
            if (vm.projectType) {
                vm.trProject.projectType = vm.projectType;
                if (
                    entity_old.projectType &&
                    vm.projectType.id === entity_old.projectType.id
                ) {
                    vm.trProject.projectTypeAttributeValues = [];
                    vm.trProject.projectTypeAttributeValues =
                        entity_old.projectTypeAttributeValues;
                } else {
                    vm.trProject.projectTypeAttributeValues = [];
                    $.each(
                        vm.projectType.projectTypeAttribute,
                        function (key, value) {
                            vm.trProject.projectTypeAttributeValues.push({
                                projectTypeAttribute:
                                    vm.projectType.projectTypeAttribute[key],
                            });
                        }
                    );
                }
            }
        }

        function save() {
            //vm.isSaving = true;
            /* var spreadAV = vm.trProject.projectTypeAttributeValues;
            for (
                var spreadAssetIndex = 0;
                spreadAssetIndex < spreadAV.length;
                spreadAssetIndex++
            ) {
                if (
                    spreadAV[spreadAssetIndex].projectTypeAttribute
                        .name === "Fiber Distance (km)"
                ) {
                    spreadAV[
                        spreadAssetIndex
                    ].attributeValue = fiberDistance;
                }
            }
            vm.trProject.projectTypeAttributeValues = spreadAV; */
                
            if (vm.trProject.id !== null) {
                TrProject.update(vm.trProject, onSaveSuccess, onSaveError);
            } else {
                TrProject.save(vm.trProject, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit("trakeyeApp:projectUpdate", result);
            vm.isSaving = false;

            var pageValue = sessionStorage.getItem("prevPage");
            if (pageValue == "user-management.new") {
                $state.go("user-management.new");
            } else {
                $state.go("tr-project");
            }
        }

        function onSaveError() {
            vm.isSaving = false;
        }


       /*  Configs.get("WorkFlowStatus", function (response) {
            vm.workFlowStatus=[];
            var workFlowStatusList = response[0].configValue.split(",");
            workFlowStatusList.forEach(function(value){
                if(value.length > 0){
                    vm.workFlowStatus.push({"configKey":value,"configValue":value});
                }
            });
            //console.log(vm.workFlowStatus);
        }); */

        Configs.get("Phases", function (response) {
            vm.workFlowPhase = response;
            //vm.workFlow.phase = "Planning Phase"
            /*  if(vm.workFlow.id == null){
                response.forEach(function(val){
                    if(val.configValue == '01'){
                        vm.workFlow.phase = val.configKey
                    }
                })
            }*/
        });

        Configs.get("RolloutTaskStatus", function (response) {
            vm.rolloutTaskStatus = response;
        });

       /*  vm.trProject.routes = []
        vm.selectEntity = function(route){
            if(route.isChecked){
                vm.trProject.routes.push(route)
            }
        }

        if(vm.trProject.id){
            if(vm.trProject.routes){
                    vm.trProject.routes.forEach(function(trEach){
                        vm.routes.filter(function (each){
                            if(trEach.id == each.id){
                                each.isChecked = true
                            }
                    })
                })
            }
        } */

       
        today.setHours(23, 59, 59);
        vm.datePickerOpenStatus = {};
		vm.openCalendar = openCalendar; 
        $scope.optionsTo = {
			maxDate: today.setDate(today.getDate())
		};
        $scope.optionsFrom = {
			maxDate: today.setDate(today.getDate())
		};
        function openCalendar(date) {
             vm.datePickerOpenStatus[date] = true;
        }
        function calculateFromDate() {
            $scope.optionsTo = {
                minDate: vm.startDate.setDate(vm.startDate.getDate()),
                maxDate: today.setDate(today.getDate())
            };
            vm.startDate.setHours(0, 0, 0, 0);
            vm.trProject.startDate = vm.startDate.getTime();
        }

        function calculateToDate() {
            $scope.optionsFrom = {
                maxDate: vm.endDate.setDate(vm.endDate.getDate())
            };
            vm.endDate.setHours(23, 59, 59, 999);
            vm.trProject.endDate = vm.endDate.getTime();
        }

        function mapStyles() {
            var styles;
            return (styles = [
                {
                    featureType: "poi",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    elementType: "labels.text.fill",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                } /* 
                {
                    elementType: "geometry.fill",
                    stylers: [
                        {
                            color: "#EFEFEF",
                        },
                    ],
                }, */,
                {
                    featureType: "transit",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    featureType: "road",
                    elementType: "labels.icon",
                    stylers: [{ visibility: "off" }],
                },
                {
                    featureType: "landscape",
                    stylers: [
                        {
                            color: "#efefef",
                        },
                    ],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry",
                    stylers: [
                        {
                            color: "#ffffff",
                        },
                    ],
                },
            ]);
        }

        locations.map = new google.maps.Map(
            document.getElementById("map_canvas"),
            {
                center: {
                    lat: parseFloat($rootScope.DEFAULT_LATITUDE),
                    lng: parseFloat($rootScope.DEFAULT_LONGITUDE),
                },
                zoom: 13,
                fullscreenControl: true,
                styles: mapStyles(),
            }
            //myOption(16, vm.getCenterEnd)
        );

        locations.map.addListener("click", function(event) {
            if (markersStore.length < 1) {
                // Add a marker to the map
                addMarker(event.latLng);

                // Get the latitude and longitude of the clicked point
                vm.trProject.latitude = event.latLng.lat();
                vm.trProject.longitude = event.latLng.lng();
            } else {
                console.log("Maximum 1 marker allowed.");
            }
        });

        function addMarker(location) {
            // Create a marker
             marker = new google.maps.Marker({
                position: location,
                map: locations.map
            });
            
            // Add the marker to the markers array
            markersStore.push(marker);
            
            if(vm.trProject.name){
                var myButton = document.getElementById('disableBtn');
            myButton.disabled = false;
            }
            
            
        }

        vm.nameChange = function (){

            if(vm.trProject.latitude){
                var myButton = document.getElementById('disableBtn');
            myButton.disabled = false;
            }
            
        }

        vm.removeMarker = function() {
            markersStore.forEach(function(marker) {
                marker.setMap(null);
              });
              
              // Clear the markers array
            markersStore.length = 0

            var myButton = document.getElementById('disableBtn');
            myButton.disabled = true;
        } 

        if (vm.trProject.id != null) {
            vm.trProjectType = vm.trProject.projectType;

            if(vm.trProject.startDate != null && vm.trProject.endDate != null){
                vm.startDate = new Date(vm.trProject.startDate)
                vm.endDate = new Date(vm.trProject.endDate)
            }
            
            if(vm.trProject.latitude != null && vm.trProject.longitude != null){
                locations.map.setCenter({lat:vm.trProject.latitude,lng:vm.trProject.longitude});
                addMarker({lat:vm.trProject.latitude,lng:vm.trProject.longitude})
            }
           
        }

        vm.colorcode = function (str){
            vm.trProject.ragStatus = str
        }
    }
})();
