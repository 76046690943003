angular.module('trakeyeApp').directive('routeImage', ['$localStorage','imageRoutePoint','$filter',function ($localStorage,imageRoutePoint,$filter) {
    return {
      restrict: 'E',
      scope: {
        obj:'=',
      },
      templateUrl: 'app/components/View/routeImage.html',
      link: function (scope) {

        var markerImage,iconImg
        var routeMarkers = []
        var iconSize = 20
        var iconMarker = "content/images/markersPoint.png" 
        var iconImage = "content/images/routePointImage.png"
        var openedInfoWindow = null;

        google.maps.event.addListener(
          scope.obj.map,
          "zoom_changed",
          function () {
              scope.obj.zoomLevel = scope.obj.map.getZoom();
               scope.$apply(); 
                  
          }
      );
  
      google.maps.event.addListener(
        scope.obj.map,
          "idle",
          function () {
              scope.obj.zoomLevel = scope.obj.map.getZoom();
                   scope.$apply(); 
          }
      );
  
      google.maps.event.addListener(scope.obj.map, 'dragend', function () {
          if(scope.obj.zoomLevel >=  15){
              var center = scope.obj.map.getCenter();
              imageRoutePoint.postCenterLatLng(scope.obj.routeId,{lat:center.lat(),lng:center.lng()},function(data){
                  if(data.length){
                      for (var i = 0; i < routeMarkers.length; i++) {
                          routeMarkers[i].setMap(null);
                        }
                        routeMarkers = []; 
                        callRouteImage(data,scope.obj.map)
                  }
              })
          }
      });

      function callRouteImage(arr,map){
          arr.forEach(function(each){
              iconImg = (each.imageAttached) ? iconImage : iconMarker
              markerImage = new google.maps.Marker({
                  position: {lat: each.latitude,lng: each.longitude},
                  map: map,
                  icon: {
                      scaledSize: new google.maps.Size(iconSize, iconSize),
                      url: iconImg,
                      anchor: new google.maps.Point(
                          iconSize / 2,
                          iconSize / 2
                      ),
                      labelOrigin: new google.maps.Point(25, 22),
                  },
                });
                
                var infowindow = new google.maps.InfoWindow({
                    content:
                        '',
                });

                  google.maps.event.addListener(
                      markerImage,
                      "click",
                      (function () {
                          
                          
                          if(each && each.id && each.imageAttached){
                              scope.imgName = (each.name == null) ? "Marker" : each.name
                              scope.imgLatitude = each.latitude
                              scope.imgLongitude = each.longitude
                              //scope.$apply(); 
                              imageRoutePoint.getImagesList(each.id,function(item){
                                  if(item.length>=2){
                                      item.forEach(function(i){
                                          i.rID = each.id
                                      })
                                      scope.nameImages = item
                                          $("#modalAttachment").show();
                                       
                                  }else{
                                    item.map(function(media){
                                        scope.newTabDisplay(each.id,media.imageId,media.imageType)
                                    })
                                  }
                                  
                                  //$scope.$apply()
                              })
                          } else{
                            if (openedInfoWindow != null) {
                                openedInfoWindow.close();
                            }
                            var timeStamp = new Date(each.createdDate);
                            timeStamp = $filter('date')(timeStamp, 'dd-MM-yyyy h:mm a');

                            var number = each.number ? "<b>Number : " + each.number + "</b><br>" : "";
                            timeStamp = timeStamp ? "<b>Time Stamp : " + timeStamp + "</b><br>" : "";

                            var infowindow = new google.maps.InfoWindow({
                                content: '<div class="infoWindowhead"><br><br>' +
                                    "<b>Latitude : " + each.latitude + "</b><br>" +
                                    "<b>Longitude : " + each.longitude + "</b><br>" +
                                    "<b>Type : " + each.type + "</b><br>" +
                                    number + timeStamp +
                                    "<br></div>"
                            });
    
                            //infowindow.mousemove;
                            infowindow.open(scope.obj.map);
                            infowindow.setPosition({
                                lat: each.latitude,
                                lng: each.longitude,
                            });
                            openedInfoWindow = infowindow;
                          }
                       })
                  );
              
              routeMarkers.push(markerImage);
           })
  
         
      }
  
        scope.closeModal = function(id) {
            data = null
            $("#" + id).removeClass("in");
            $(".modal-backdrop").remove();
            $("#" + id).hide();
        }

        scope.newTabDisplay = function (id, imgid, str) {
            var request = new XMLHttpRequest()
            request.open(
                "GET",
                'api/routepoint-images/' + id + '/' + imgid,
                true
            );
            request.setRequestHeader(
                "Authorization",
                "Bearer " + $localStorage.authenticationToken
            );
            request.responseType = "blob";
            request.onload = response;
            function response(e) {
                if (this.status === 200) {
                    var urlCreator = window.URL || window.webkitURL;
                    if (str == "MP4") {
                        var videoUrl = urlCreator.createObjectURL(this.response);
                        var video = document.createElement('video');
                        video.src = videoUrl;
                        video.controls = true;
                        video.autoplay = true;
                        video.style.maxHeight  = '80vh';

                        var videoWindow = window.open("", "_blank");
                        videoWindow.document.body.appendChild(video);
                    } else {
                        var imageUrl = urlCreator.createObjectURL(this.response);
                        window.open(imageUrl, "_blank");
                    }
                } else {
                    alert("Error " + this.status)
                }
            }
            request.send();
        }

      }
      
    };
  }]);
  