(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("TrTaskDetailController", TrTaskDetailController);

    TrTaskDetailController.$inject = [
        "$scope",
        "$rootScope",
        "$stateParams",
        "previousState",
        "entity",
        "TrTask",
        "User",
        "TaskType",
        "TrTaskImages",
        "TrTaskRecordings",
        "AlertService",
        "$window",
        "$localStorage",
        "getTaskImage",
        "Configs",
        "dailyLogByTaskId",
        "taskComment",
        "DailyLogs",
        "SummaryPost",
        "TaskPathById",
        "pagingParams",
        "paginationConstants",
        "ParseLinks",
        "$state",
        "assignee",
        "TaskLogTaskReportData",
        "DailyLogByTaskIdWithType",
        "imageRoutePoint",
        "DailyLogByTaskIdWithTypePagination",
        "RouteByID"
    ];

    function TrTaskDetailController(
        $scope,
        $rootScope,
        $stateParams,
        previousState,
        entity,
        TrTask,
        User,
        TaskType,
        TrTaskImages,
        TrTaskRecordings,
        AlertService,
        $window,
        $localStorage,
        getTaskImage,
        Configs,
        dailyLogByTaskId,
        taskComment,
        DailyLogs,
        SummaryPost,
        TaskPathById,
        pagingParams,
        paginationConstants,
        ParseLinks,
        $state,
        assignee,
        TaskLogTaskReportData,
        DailyLogByTaskIdWithType,
        imageRoutePoint,
        DailyLogByTaskIdWithTypePagination,
        RouteByID
    ) {
        var vm = this;

        vm.trTask = entity;
        vm.trTask.assignee = assignee;
        vm.previousState = previousState.name;
        vm.decodeImage = decodeImage;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        $scope.search = pagingParams.search;
        //vm.transition = transition;
        vm.progresstransition = progresstransition;
        vm.obstacletransition = obstacletransition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.progressitemsPerPage = paginationConstants.progressitemsPerPage;
        vm.obstacleitemsPerPage = paginationConstants.obstacleitemsPerPage;
        vm.showTaskLogReport = showTaskLogReport;
        var unsubscribe = $rootScope.$on(
            "trakeyeApp:trTaskUpdate",
            function (event, result) {
                vm.trTask = result;
            }
        );
        vm.showTaskAttachment = false;
        var locations = {};
        var openedInfoWindow = null;
        var markers = [];
        var coordinateList = [];
        var chainageList = [];
        var markerList = [];
        var iconSize = 20;
        var hexColors = [
            "#FF5733",
            "#45B39D",
            "#9B59B6",
            "#F4D03F",
            "#3498DB",
            "#E74C3C",
            "#2ECC71",
            "#1ABC9C",
            "#D35400",
            "#8E44AD",
            "#27AE60",
            "#2980B9",
            "#C0392B",
            "#16A085",
            "#9B59B6",
            "#7F8C8D",
            "#F39C12",
        ];
        var center = {
            lat: parseFloat($rootScope.DEFAULT_LATITUDE),
            lng: parseFloat($rootScope.DEFAULT_LONGITUDE),
        };

        locations.map = new google.maps.Map(
            document.getElementById("map_canvasStart"),
            myOption(16, center)
        );


        var route = null;

        if (vm.trTask.routeTask != null && vm.trTask.routeTask.length && vm.trTask.routeTask[0].routeId) {
            RouteByID.get(vm.trTask.routeTask[0].routeId, function (response) {
                route = response

                if (route && route.assets.length) {
                    route.assets.forEach(function (element) {
                        locations.map.setCenter({
                            lat: element.assetCoordinates[0].latitude, lng:
                                element.assetCoordinates[0].longitude
                        });



                        if (element.assetCoordinates.length) {

                            if (element.assetType.layout == "SPREAD") {
                                vm.tempPath = []
                                element.assetCoordinates.forEach(function (item) {
                                    vm.tempPath.push({ lat: item.latitude, lng: item.longitude })
                                })
                                var polyline = new google.maps.Polyline({
                                    path: vm.tempPath,
                                    geodesic: true,
                                    strokeColor: element.assetType.colorcode,
                                    strokeOpacity: 0.6,
                                    strokeWeight: 4,
                                });
                                //addClick(polyline);
                                // Set the map to display the polyline
                                polyline.setMap(locations.map);
                            }

                            if (element.assetType.layout == "FIXED") {
                                element.assetCoordinates.forEach(function (e) {
                                    var marker = new google.maps.Marker({
                                        position: { lat: e.latitude, lng: e.longitude },
                                        map: locations.map,
                                        icon: {
                                            scaledSize: new google.maps.Size(iconSize, iconSize),
                                            url: element.assetType.imagePath,
                                            anchor: new google.maps.Point(
                                                iconSize / 2,
                                                iconSize / 2
                                            ),
                                            labelOrigin: new google.maps.Point(25, 22),
                                        },
                                    });


                                })
                            }
                        }

                    })

                }
                /* TaskPathById.get(entity.id, function (response) {
                    drawPolygon(entity.id, response, {}, 10, 1, "task", false, 1);
                    if (response.COORDINATE != undefined) {
                        if (response.COORDINATE.length > 0) {
                            locations.map.setCenter({ lat: response.COORDINATE[0].latitude, lng: response.COORDINATE[0].longitude });
                        }
                    }
                }); */
            }, function (err) {
                /* TaskPathById.get(entity.id, function (response) {
                     drawPolygon(entity.id, response, {}, 10, 1, "task", false, 1);
                     if (response.COORDINATE != undefined) {
                         if (response.COORDINATE.length > 0) {
                             locations.map.setCenter({ lat: response.COORDINATE[0].latitude, lng: response.COORDINATE[0].longitude });
                         }
                     }
                 });  */
            })
        }

        function myOption(zoom, center) {
            var myOptions;
            return (myOptions = {
                zoom: zoom,
                center: center,
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: mapStyles(),
            });
        }
        function mapStyles() {
            var styles;
            return (styles = [
                {
                    featureType: "poi",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    elementType: "labels.text.fill",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                } /* 
                {
                    elementType: "geometry.fill",
                    stylers: [
                        {
                            color: "#EFEFEF",
                        },
                    ],
                }, */,
                {
                    featureType: "transit",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    featureType: "road",
                    elementType: "labels.icon",
                    stylers: [{ visibility: "off" }],
                },
                {
                    featureType: "landscape",
                    stylers: [
                        {
                            color: "#efefef",
                        },
                    ],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry",
                    stylers: [
                        {
                            color: "#ffffff",
                        },
                    ],
                },
            ]);
        }

        vm.dailyLogs = [];
        vm.obstacleLogs = []

        //callLogs()
        function callLogs() {
            // dailyLogByTaskId.query(
            //     {
            //         search: $scope.search,
            //         page: 0,
            //         size: 65, //vm.itemsPerPage
            //         sort: sort(),
            //         id: entity.id
            //     },
            //     function (response, headers) {
            //         vm.links = ParseLinks.parse(headers("Link"));
            //         vm.totalItems = headers("X-Total-Count");
            //         vm.queryCount = vm.totalItems;
            //         //vm.page = pagingParams.page;

            //         if (response.length) {
            //             vm.obstacleLogs = response.filter(function (each) {
            //                 return each.taskLogType == "OBSTACLE"
            //             })

            //             vm.dailyLogs = response.filter(function (each) {
            //                 return each.taskLogType == "PROGRESS"
            //             })
            //             showMapData();
            //         }
            //     }
            // );

            DailyLogByTaskIdWithType.query(
                {
                    search: $scope.search,
                    page: 0,
                    size: 65,
                    sort: sort(),
                    id: entity.id,
                    type: 'PROGRESS'

                },
                onProgressSuccess,
                onProgressError
            );

            function onProgressSuccess(response, headers) {
                vm.progresslinks = ParseLinks.parse(headers("Link"));
                vm.progresstotalItems = headers("X-Total-Count");
                vm.progressqueryCount = vm.totalItems;
                vm.dailyLogs = response;

                if (vm.dailyLogs.length) {
                    callRouteImage(vm.dailyLogs, locations.map)
                    /* vm.dailyLogs.forEach(function(item){
                    imageRoutePoint.getUserTaskLogImages(item.id,function(){
                        callRouteImage(item,locations.map)
                    })
                    }) */
                }


                DailyLogByTaskIdWithType.query(
                    {
                        search: $scope.search,
                        page: 0,
                        size: 65,
                        sort: sort(),
                        id: entity.id,
                        type: 'OBSTACLE'

                    },
                    onObstacleSuccess,
                    onObstacleError
                );

                function onObstacleSuccess(response, headers) {
                    vm.obstaclelinks = ParseLinks.parse(headers("Link"));
                    vm.obstacletotalItems = headers("X-Total-Count");
                    vm.obstaclequeryCount = vm.totalItems;
                    vm.obstacleLogs = response;

                    if (vm.obstacleLogs.length) {
                        callRouteImage(vm.obstacleLogs, locations.map)
                        /*  vm.obstacleLogs.forEach(function(item){
                             imageRoutePoint.getUserTaskLogImages(item.id,function(){
                                 callRouteImage(item,locations.map)
                             })
                         }) */
                    }

                    //showMapData();
                }
                function onObstacleError(error) {
                    AlertService.error(error.data.message);
                }
            }
            function onProgressError(error) {
                AlertService.error(error.data.message);
            }



            function sort() {
                var result = [
                    vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                ];
                if (vm.predicate !== "id") {
                    result.push("id");
                }
                return result;
            }
            TaskPathById.get(entity.id, function (response) {
                drawPolygon(entity.id, response, {}, 10, 1, "task", false, 1);
                if (response.COORDINATE != undefined) {
                    if (response.COORDINATE.length > 0) {
                        locations.map.setCenter({ lat: response.COORDINATE[0].latitude, lng: response.COORDINATE[0].longitude });
                    }
                }
            });
        }

        function sort() {
            var result = [vm.predicate + "," + (vm.reverse ? "asc" : "desc")];
            if (vm.predicate !== "id") {
                // result.push('id');
            }
            return result;
        }


        Configs.get("TaskImage", function (response) {
            if (response.length > 0) {
                if (response[0].configValue == "True") {
                    vm.showTaskAttachment = true;
                }
            }
        });

        vm.imageInNewTab = function (img) {
            var imgWindow = window.open();
            imgWindow.document.write(
                '<img width="25%"   src="data:image/jpeg;base64,' + img + '">'
            );
            imgWindow.document.close();
        };

        $scope.newTabDisplay = function (id) {
            var request = new XMLHttpRequest();
            request.open(
                "GET",
                "api/trakeyetaskimages/" + vm.trTask.id + "/" + id,
                true
            );
            request.setRequestHeader(
                "Authorization",
                "Bearer " + $localStorage.authenticationToken
            );
            request.responseType = "blob";
            request.onload = response;

            function response(e) {
                if (this.status === 200) {
                    var urlCreator = window.URL || window.webkitURL;
                    var imageUrl = urlCreator.createObjectURL(this.response);
                    window.open(imageUrl, "_blank");
                }
            }
            request.send();
        };

        $scope.$on("$destroy", unsubscribe);

        $scope.go_back = function () {
            $window.history.back();
        };

        $scope.allimages = false;
        //		$scope

        function getRandomString(arr) {
            var randomIndex = Math.floor(Math.random() * arr.length);
            return arr[randomIndex];
        }

        function drawPolygon(id, value, details, zIndex, strokeWeight, type, canClick, zIndexVal) {

            var verified = details.verified ? "<b>Verified : " + details.verified + "</b></br>" : ""
            if (value.COORDINATE == undefined) {
                value = value.value;
            }
            if (value == undefined) {
                return;
            }

            var latlng = {
                lat: value.COORDINATE[0].latitude,
                lng: value.COORDINATE[0].longitude,
            };
            // value.COORDINATE.sort(function (a, b) { return a.id - b.id });
            var path = [];
            if (value.COORDINATE != null) {
                value.COORDINATE.forEach(function (location) {
                    path.push({ lat: location.latitude, lng: location.longitude });
                });
            }

            var infowindow = new google.maps.InfoWindow({
                content:
                    '<div class="infoWindowhead">' +
                    "name" +
                    '<div class="infoWindowContent"> <b>ID :</b> ' +
                    id +
                    "</div></div>",
            });

            infowindow.setPosition(latlng);

            var marker = new google.maps.Marker({
                position: latlng,
                map: locations.map,
                icon: {
                    scaledSize: new google.maps.Size(20, 20),
                    url: "content/images/dot_marker.png",
                    anchor: new google.maps.Point(
                        iconSize / 2,
                        iconSize / 2
                    ),
                    labelOrigin: new google.maps.Point(25, 22),
                },
            });
            // var strokeColor = "#ff6a08";

            // if (color == "green") {
            //     strokeColor = "#00e658";
            // } else if (color == "blue") {
            //     strokeColor = "#0066CC";
            // }
            var strokeOpacity = 1.0;
            var strokeColor = "#00e658";
            strokeColor = getRandomString(hexColors);
            if (type == "task") {
                //strokeColor = "#0066CC";
                strokeColor = "#8a0101";
                strokeOpacity = 0.3;
            } else if (type == "PROGRESS") {
                strokeColor = "#00e658";
            } else if (type == "OBSTACLE") {
                strokeColor = "#ff0000";
            } else {
                strokeColor = "#b5b0b0"
            }
            var polyline = new google.maps.Polyline({
                path: path,
                geodesic: true,
                strokeColor: strokeColor,
                strokeOpacity: strokeOpacity,
                strokeWeight: 4,
                zIndex: zIndexVal
            });
            var disVal = 0;
            if (path.length > 0) {
                for (var index in path) {
                    if (index > 0) {
                        disVal += distanceCal(path[index].lat, path[index].lng, path[index - 1].lat, path[index - 1].lng);
                    }
                }
            }
            // Set the map to display the polyline
            polyline.setMap(locations.map);

            google.maps.event.addListener(
                polyline,
                "mouseout",
                function (event) {
                    infowindow.close();
                }
            );


            if (canClick) {
                google.maps.event.addListener(
                    polyline,
                    "click",
                    function (event) {
                        if (openedInfoWindow != null) {
                            openedInfoWindow.close();
                        }
                        infowindow.setContent(
                            '<div class="infoWindowhead"></br></br>' +
                            "<b>Date : " + new Date(details.workDate).toString("yyyy-MM-dd HH:mm") + "</b></br>" +
                            "<b>Length : " + details.workLength + "</b></br>" +
                            "<b>Hours : " + details.numberOfHours + "</b><br>" +
                            "<b>Created by : " + details.createdBy + "</b></br>" +
                            "<b>Type : " + details.taskLogType + "</b></br>" +
                            verified +
                            "</br></div>"
                        );

                        infowindow.mousemove;
                        infowindow.open(locations.map);
                        infowindow.setPosition(latlng);
                        openedInfoWindow = infowindow;

                    }
                );
            }

            if (canClick) {
                google.maps.event.addListener(
                    polyline,
                    "click",
                    function (event) {
                        if (openedInfoWindow != null) {
                            openedInfoWindow.close();
                        }
                        infowindow.setContent(
                            '<div class="infoWindowhead"><b>' +
                            'Task Log'
                            +
                            "</b></br></br><b>Date : " + new Date(details.workDate).toString("yyyy-MM-dd HH:mm") + "</b></br>" +
                            "<b>Length : " + details.workLength + "</b></br>" +
                            "<b>Hours : " + details.numberOfHours + "</b><br>" +
                            "<b>Created by : " + details.createdBy + "</b></br>" +
                            "<b>Type : " + details.taskLogType + "</b></br>" +
                            verified +
                            "</br></div>"
                        );

                        infowindow.mousemove;
                        infowindow.open(locations.map);
                        infowindow.setPosition(latlng);
                        openedInfoWindow = infowindow;

                    }
                );
            }

            // tempPolygon.setMap(locations.map);
            locations.map.setCenter(latlng);
            // locations.map.setZoom($rootScope.rolloutMapInitZoom);
            return polyline;
        }

        function distanceCal(lat1, lon1, lat2, lon2) {
            var theta = lon1 - lon2;
            var dist = Math.sin(degrees_to_radians(lat1)) * Math.sin(degrees_to_radians(lat2)) +
                Math.cos(degrees_to_radians(lat1)) * Math.cos(degrees_to_radians(lat2)) * Math.cos(degrees_to_radians(theta));
            dist = Math.acos(dist);
            dist = radians_to_degrees(dist);
            dist = dist * 60 * 1.1515;
            dist = dist * 1.609344;
            if (isNaN(dist)) {
                return 0.0;
            } else {
                return (dist);
            }
        }

        function degrees_to_radians(degrees) {
            var pi = Math.PI;
            return degrees * (pi / 180);
        }

        function radians_to_degrees(radians) {
            var pi = Math.PI;
            return radians * (180 / pi);
        }

        $scope.toggle = function (id) {
            switch (id) {
                case "image":
                    $scope.allimages = !$scope.allimages;
                    vm.showImages = !vm.showImages;
                    if ($scope.allimages) {
                        TrTaskImages.query(
                            {
                                taskId: vm.trTask.id,
                            },
                            onSuccess,
                            onError
                        );

                        function onSuccess(data, headers) {
                            // console.log('Recived data-----------');

                            vm.taskImages = data.filter(function (taskImages) {
                                return taskImages.image;
                            });
                        }

                        function onError(error) {
                            AlertService.error(error.data.message);
                        }
                    }
                    break;

                case "termination":
                    $scope.terminationDetails = !$scope.terminationDetails;
                    vm.showTermination = !vm.showTermination;
                    break;

                case "fms":
                    $scope.fmsDetails = !$scope.fmsDetails;
                    vm.showFMS = !vm.showFMS;
                    break;

                case "splicing":
                    $scope.splicingDetails = !$scope.splicingDetails;
                    vm.showSplicing = !vm.showSplicing;
                    break;

                case "cable":
                    $scope.cableDetails = !$scope.cableDetails;
                    vm.showCable = !vm.showCable;
                    break;

                case "pdf":
                    $scope.allpdf = !$scope.allpdf;
                    vm.showPdf = !vm.showPdf;
                    if ($scope.allpdf) {
                        getTaskImage.get(vm.trTask.id, function (res) {
                            vm.attachments = res;
                        });
                    }

                    break;

                case "log":
                    $scope.showLog = !$scope.showLog;
                    vm.showLog = !vm.showLog;
                    break;

                default:
                    console.log("");
            }

            /*
        	
            $scope.allimages = !$scope.allimages;
        	
            var audioTag = document.createElement("AUDIO");
            audioTag.setAttribute("src","http://us-east-1.linodeobjects.com/trakeye-media-files/task_"+vm.trTask.id+".mp3");
            audioTag.setAttribute("controls", "controls");
            audioTag.setAttribute("controlsList", "nodownload");

            var mydiv = document.getElementById("audioLayout");
        	
            if ($scope.allimages) {
                TrTaskImages.query({
                    taskId : vm.trTask.id

                }, onSuccess, onError);

                function onSuccess(data, headers) {
                    // console.log('Recived data-----------');
                    vm.taskImages = data;
                }

                function onError(error) {
                    AlertService.error(error.data.message);
                }
                var mydivChild = document.createElement('div');
                mydivChild.setAttribute("id", "audioChild");
                mydiv.appendChild(mydivChild);
                mydivChild.appendChild(audioTag);
            }else{
                var mydivChild = document.getElementById("audioChild");
                mydivChild.remove();
            }
        */
        };

        //		$scope.playAudio = function() {
        ////			var urlStr = "http://us-east-1.linodeobjects.com/trakeye-media-files/task_"+vm.trTask.id+".mp3";
        //            var urlStr = "http://us-east-1.linodeobjects.com/trakeye-media-files/task_15570572.mp3";
        //	        var audio = new Audio(urlStr);
        //	        audio.play();
        //	    };

        function decodeImage(img) {
            return window.atob(img);
        }

        /*  vm.toggleAccordion = function(item) {
             if (item.active) {
                 item.active = false;
             } else {
                 // Close all other items
                 vm.dailyLogs.forEach(function (otherItem) {
                     otherItem.active = false;
                 });
                 item.active = true;
             }
         }; */
        function createMetaTag(attrName, attrValue, content) {
            var meta = document.createElement('meta');
            meta.setAttribute(attrName, attrValue);
            meta.setAttribute('content', content);
            return meta;
        }

        function createTable(classNames) {
            var table = document.createElement('table');
            table.setAttribute('border', '1');
            table.setAttribute('cellpadding', '5');
            table.setAttribute('cellspacing', '0');
            table.setAttribute('id', 'sheet0');
            table.setAttribute('class', classNames);
            return table;
        }

        function createTableCell(tagName, textContent) {
            var cell = document.createElement(tagName);
            cell.textContent = textContent;
            return cell;
        }

        function showTaskLogReport(taskId) {
            console.log(taskId)
            vm.isReportGenerated = false;
            TaskLogTaskReportData.query(
                {
                    reportType: "daily",
                    taskId: taskId
                },
                onSuccess,
                onError
            );

            function onSuccess(data) {
                var htmlDoc = document.createElement('html');
                var head = document.createElement('head');
                var meta1 = createMetaTag('http-equiv', 'Content-Type', 'text/html; charset=utf-8');
                var meta2 = createMetaTag('name', 'generator', 'PhpSpreadsheet, https://github.com/PHPOffice/PhpSpreadsheet');
                var meta3 = createMetaTag('name', 'author', 'Apache POI');
                var meta4 = createMetaTag('name', 'company', 'Microsoft Corporation');
                head.appendChild(meta1);
                head.appendChild(meta2);
                head.appendChild(meta3);
                head.appendChild(meta4);
                var body = document.createElement('body');
                var table = createTable('sheet0 gridlines');
                var thead = document.createElement('thead');
                var tbody = document.createElement('tbody');

                function createTable(classNames) {
                    var table = document.createElement('table');
                    table.setAttribute('border', '1');
                    table.setAttribute('cellpadding', '5');
                    table.setAttribute('cellspacing', '0');
                    table.setAttribute('id', 'sheet0');
                    table.setAttribute('class', classNames);
                    return table;
                }

                function createMetaTag(attrName, attrValue, content) {
                    var meta = document.createElement('meta');
                    meta.setAttribute(attrName, attrValue);
                    meta.setAttribute('content', content);
                    return meta;
                }

                function createTableCell(tag, content) {
                    var cell = document.createElement(tag);
                    cell.textContent = content;
                    cell.style.fontSize = "13px";
                    return cell;
                }

                thead.className = 'thReport';
                table.appendChild(thead);
                tbody.className = 'tdReport';
                table.appendChild(tbody);
                var styleElement = document.createElement('style');
                styleElement.textContent = "\
                .subheader {\
                    margin: auto;\
                }\
                .thReport > tr > th{\
                    text-align: center;\
                    padding: 10px;\
                }\
                .tdReport > tr > td {\
                    text-align: center; \
                    padding: 10px;\
                }\
                ";
                document.head.appendChild(styleElement);

                var colors = ['#d6d5c9', '#ff9b85', '#ffcccc', '#ccccff', '#fae588', '#b0f2b4', '#abd3ff', '#d0b17a', '#eacdc2',];
                function getRandomColor() {
                    if (colors.length === 0) {
                        colors = ['#d6d5c9', '#ff9b85', '#ffcccc', '#ccccff', '#fae588', '#b0f2b4', '#abd3ff', '#d0b17a', '#eacdc2'];
                    }
                    var index = Math.floor(Math.random() * colors.length);
                    var color = colors[index];
                    colors.splice(index, 1);
                    return color;
                }


                var groupedData = {};
                data.forEach(function (item) {
                    if (!groupedData[item.date]) {
                        groupedData[item.date] = [];
                    }
                    groupedData[item.date].push(item);
                });
                var names = getUniqueNames(data);
                var nameHeaderRow = document.createElement('tr');
                var serialNumberHeader = createTableCell('th', 'S.No');
                serialNumberHeader.style.fontWeight = 'bold';
                serialNumberHeader.style.backgroundColor = '#7dcfb6';
                serialNumberHeader.rowSpan = 2;
                nameHeaderRow.appendChild(serialNumberHeader);
                var dateHeader = createTableCell('th', 'Date');
                dateHeader.style.backgroundColor = '#7dcfb6';
                dateHeader.style.fontWeight = 'bold';
                dateHeader.rowSpan = 2;
                nameHeaderRow.appendChild(dateHeader);
                var typeHeaderRow = document.createElement('tr');

                names.forEach(function (name, index) {
                    var nameData = data.filter(function (item) { return item.name === name; });
                    var availableTypes = getUniqueTypes(nameData);
                    var nameHeaderCell = createTableCell('th', name);
                    nameHeaderCell.style.backgroundColor = getRandomColor();
                    nameHeaderCell.style.fontWeight = 'bold';
                    nameHeaderCell.colSpan = availableTypes.length;
                    nameHeaderRow.appendChild(nameHeaderCell);
                    availableTypes.forEach(function (type) {
                        var typeHeaderCell = createTableCell('th', type);
                        typeHeaderCell.style.backgroundColor = nameHeaderCell.style.backgroundColor;
                        typeHeaderCell.style.fontWeight = 'bold';
                        typeHeaderRow.appendChild(typeHeaderCell);
                    });
                });
                var totalProgressHeader = createTableCell('th', 'Total Progress');
                totalProgressHeader.style.backgroundColor = '#cfe0c3';
                totalProgressHeader.style.fontWeight = 'bold';
                totalProgressHeader.colSpan = 3;
                nameHeaderRow.appendChild(totalProgressHeader);
                var subHeaders = [
                    {
                        type: 'Total',
                    },
                    {
                        type: "Ducting",
                    }, {
                        type: "Validation",

                    }
                ];
                subHeaders.forEach(function (subHeader) {
                    var subHeaderCell = createTableCell('th', subHeader.type);
                    subHeaderCell.style.backgroundColor = '#cfe0c3';
                    subHeaderCell.style.fontWeight = 'bold';
                    typeHeaderRow.appendChild(subHeaderCell);
                });
                thead.appendChild(nameHeaderRow);
                thead.appendChild(typeHeaderRow);
                var totals = {};

                Object.keys(groupedData)
                    .sort(function (a, b) {
                        var dateA = a.split('-').reverse().join('-');
                        var dateB = b.split('-').reverse().join('-');
                        return new Date(dateA) - new Date(dateB);
                    })
                    .forEach(function (date, index) {
                        var totalLength = 0;
                        var totalValidation = 0;
                        names.forEach(function (name) {
                            var nameData = data.filter(function (item) { return item.name === name && item.date === date; });
                            var ductingLength = 0;
                            var hddLength = 0;
                            var horizontalDirectionalDrilling = 0
                            var validationLength = 0;
                            nameData.forEach(function (item) {
                                if (item.type === 'Ducting') {
                                    ductingLength += item.length;
                                }
                                else if (item.type === 'HDD') {
                                    hddLength += item.length;

                                }
                                else if (item.type === 'HDD ( Horizontal Directional Drilling )') {
                                    horizontalDirectionalDrilling += item.length;
                                    console.log(horizontalDirectionalDrilling)
                                }
                                else if (item.type === 'Validation' || item.type === 'Validation (T&D)') {
                                    validationLength += item.length;
                                }
                            });
                            console.log(totalLength)
                            totalLength += (ductingLength + hddLength + horizontalDirectionalDrilling);
                            totalValidation += validationLength;

                        });
                        var tr = document.createElement('tr');
                        var serialNumberCell = createTableCell('td', index + 1);
                        serialNumberCell.style.fontSize = "13px";
                        tr.appendChild(serialNumberCell);
                        var dateCell = createTableCell('td', date);
                        dateCell.style.width = '77px'
                        dateCell.style.whiteSpace = 'nowrap';
                        dateCell.style.fontSize = "13px";
                        tr.appendChild(dateCell);
                        names.forEach(function (name) {
                            var nameData = data.filter(function (item) { return item.name === name; });
                            var availableTypes = getUniqueTypes(nameData);

                            availableTypes.forEach(function (type) {
                                var item = groupedData[date].find(function (item) {
                                    return item.name === name && item.type === type;
                                });
                                var lengthValue = item ? item.length : 0;
                                var td = createTableCell('td', lengthValue);

                                tr.appendChild(td);
                                if (!totals[type]) {
                                    totals[type] = 0;
                                }
                                totals[type] += lengthValue;
                            });
                        });
                        var total = 0;
                        for (var i = 2; i < tr.cells.length; i++) {
                            total += parseInt(tr.cells[i].textContent);
                        }
                        var columnValueCell = createTableCell('td', total);
                        columnValueCell.colSpan = 1;
                        tr.appendChild(columnValueCell);
                        var remainingDuctingCell = createTableCell('td', totalLength);
                        tr.appendChild(remainingDuctingCell);
                        var remainingValidationCell = createTableCell('td', totalValidation);
                        tr.appendChild(remainingValidationCell);
                        if (index === 0) {
                            totals['Total Progress Ducting'] = 0;
                            totals['Total Progress Validation'] = 0;
                        }
                        totals['Total Progress Ducting'] += totalLength;
                        totals['Total Progress Validation'] += totalValidation;
                        tbody.appendChild(tr);
                    });

                var staticColumns = ['Total', 'Balance Validation', 'Balance Ducting', 'Material Issued', 'Wastage', 'Stock at Site'];
                var allAvailableTypes = [];
                names.forEach(function (name) {
                    var nameData = data.filter(function (item) { return item.name === name; });
                    allAvailableTypes.push.apply(allAvailableTypes, getUniqueTypes(nameData));
                });
                var availableTypes = allAvailableTypes;
                var maxUniqueTypesCount = availableTypes.length;
                var totalLength = maxUniqueTypesCount + subHeaders.length;

                var columnTotals = {};
                for (var i = 2; i < totalLength; i++) {
                    var total = 0;
                    for (var j = 0; j < tbody.rows.length; j++) {
                        var cellValue = parseInt(tbody.rows[j].cells[i].textContent) || 0;
                        total += cellValue;
                    }
                    columnTotals[i] = total;
                }
                staticColumns.forEach(function (column) {
                    var staticRow = document.createElement('tr');
                    var columnNameCell = createTableCell('td', column);
                    columnNameCell.colSpan = 2;
                    columnNameCell.style.backgroundColor = '#cfe0c3';
                    columnNameCell.style.fontSize = "13px";
                    columnNameCell.style.fontWeight = 'bold';
                    staticRow.appendChild(columnNameCell);
                    if (column == 'Total') {
                        for (var i = 2; i < totalLength; i++) {
                            var columnValueCell = createTableCell('td', columnTotals[i].toString());
                            columnValueCell.colSpan = 1;
                            staticRow.appendChild(columnValueCell);
                        }
                        var columnValueCell = createTableCell('td', totals['Total Progress Ducting']);
                        columnValueCell.colSpan = 1;
                        staticRow.appendChild(columnValueCell);
                        var columnValueCell = createTableCell('td', totals['Total Progress Validation']);
                        columnValueCell.colSpan = 1;
                        staticRow.appendChild(columnValueCell);
                    } else {
                        for (var i = 0; i < totalLength; i++) {
                            var columnValueCell = createTableCell('td', '0');
                            columnValueCell.colSpan = 1;
                            staticRow.appendChild(columnValueCell);
                        }
                    }
                    tbody.appendChild(staticRow);
                });
                table.appendChild(thead);
                table.appendChild(tbody);
                body.appendChild(table);
                htmlDoc.appendChild(body);
                htmlDoc.appendChild(head);
                var htmlString = htmlDoc.outerHTML;
                var containerDiv = document.getElementById("generatedHtmlContainer");
                containerDiv.innerHTML = htmlString;

                function getUniqueNames(data) {
                    var uniqueNames = [];
                    data.forEach(function (item) {
                        if (uniqueNames.indexOf(item.name) === -1) {
                            uniqueNames.push(item.name);
                        }
                    });
                    return uniqueNames;
                }
                function getUniqueTypes(data) {
                    var uniqueTypes = [];
                    data.forEach(function (item) {
                        if (uniqueTypes.indexOf(item.type) === -1) {
                            uniqueTypes.push(item.type);
                        }
                    });
                    return uniqueTypes;
                }
                // Set background color for specified cells
                var jcbCells = document.querySelectorAll('.row1 .column0'); // Select cells in row 1, column 0 (JCB)
                var hddCells = document.querySelectorAll('.row1 .column1'); // Select cells in row 1, column 1 (HDD)

                // Loop through JCB cells and set background color to light green
                jcbCells.forEach(function (cell) {
                    cell.style.backgroundColor = 'lightgreen';
                });

                // Loop through HDD cells and set background color to light green
                hddCells.forEach(function (cell) {
                    cell.style.backgroundColor = 'lightgreen';
                });

                // Get the Total row
                // Select all relevant rows
                var relevantRows = ['.row2', '.row3', '.row5', '.row6'];

                // Loop through each relevant row
                relevantRows.forEach(function (rowClass) {
                    var cells = document.querySelectorAll(rowClass + ' td');

                    // Loop through the cells in the row
                    cells.forEach(function (cell) {
                        // Check if the content of the cell is '0' or empty
                        if (cell.textContent.trim() === '0' || cell.textContent.trim() === '') {
                            // Set background color to white
                            cell.style.backgroundColor = 'white';
                        }
                    });
                });

                vm.isReportGenerated = true;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function showMapData(type) {
            taskComment.getTaskLogsPaths(entity.id, function (response) {
                var logCoorList = [];
                var keys = Object.keys(response);
                keys.forEach(function (value) {
                    logCoorList.push(response[value]);
                });
                logCoorList.forEach(function (value, index) {
                    var logType = "";
                    if(vm.dailyLogsList.length && type == "PROGRESS"){
                        vm.dailyLogsList.forEach(function (logs) {
                            if (value.id == logs.id && logs.taskLogType == "PROGRESS") {
                                logType = logs.taskLogType;
                                //console.log(logs);
                                drawPolygon(keys[index], value, logs, 1, 6, logType, true, 2);
                            }
                        });
                    }
                   

                    if(vm.obstacleLogsList.length && type == "OBSTACLE"){
                        vm.obstacleLogsList.forEach(function (logs) {
                            if (value.id == logs.id && logs.taskLogType == "OBSTACLE") {
                                logType = logs.taskLogType;
                                //console.log(logs);
                                drawPolygon(keys[index], value, logs, 1, 6, logType, true, 2);
                            }
                        });
                    }
                  

                });
            });
        }

        vm.closeModal = function (id) {
            $("#" + id).removeClass("in");
            $(".modal-backdrop").remove();
            $("#" + id).hide();
        };

        $scope.tab = 6;

        TaskPathById.get(entity.id, function (response) {
            drawPolygon(entity.id, response, {}, 10, 1, "task", false, 1);
            if (response.COORDINATE != undefined) {
                if (response.COORDINATE.length > 0) {
                    locations.map.setCenter({ lat: response.COORDINATE[0].latitude, lng: response.COORDINATE[0].longitude });
                }
            }
        });

        callLogsPagination("PROGRESS")
        callLogsPagination("OBSTACLE")
        
        vm.page = 1
        $scope.isSet = function (tabNum) {
            return $scope.tab === tabNum;
        };
        $scope.setTab = function (newTab) {

            vm.isShowComment = false
            if (newTab == 1 || newTab == 5) {
                vm.itemsPerPage = 10
                vm.page = 1

                vm.progresspage = 0;
                vm.obstaclepage = 0;

                if (newTab == 1) {
                    vm.dailyLogsList = []
                    callLogsPagination("PROGRESS")
                }
                if (newTab == 5) {
                    vm.obstacleLogsList = []
                    callLogsPagination("OBSTACLE")
                }

            }

            /*  if(newTab == 2){
                 //Comment Box
             vm.objectComments = {}
             vm.objectComments.entityId = entity.id
             vm.objectComments.entityName = "TASK"
             } */
            if (newTab == 4) {
                showTaskLogReport(vm.trTask.id);
            }

            if (newTab == 7) {
                vm.objectMaterialTxnTab = {}
                vm.objectMaterialTxnTab.id = vm.trTask.id
                vm.objectMaterialTxnTab.name = "taskId"
                vm.objectMaterialTxnTab.sectionId = vm.trTask.sectionTask.length ? vm.trTask.sectionTask[0].sectionId : null
            }

            if (newTab == 8) {
                vm.objectResourceTxnTab = {}
                vm.objectResourceTxnTab.id = vm.trTask.id
                vm.objectResourceTxnTab.name = "taskId"
                vm.objectResourceTxnTab.sectionId = vm.trTask.sectionTask.length ? vm.trTask.sectionTask[0].sectionId : null
            }
            $scope.tab = newTab;
        };

        vm.showModal = function (id) {
            $("#deleteLogs").show();
            vm.dailyLogid = id;
        };

        vm.deleteLogs = function () {
            DailyLogs.delete({ id: vm.dailyLogid }, function () {
                vm.dailyLogsList = []
                callLogsPagination("PROGRESS")
                vm.obstacleLogsList = []
                callLogsPagination("OBSTACLE")
                $("#deleteLogs").hide();
            });
        };

        SummaryPost.dailyLogsTx(
            {
                taskIds: [vm.trTask.id],
            },
            function (response) {
                vm.dailyLogsTxSummary = response;
            }
        );

        vm.objectRouteImg = {}

        if (vm.trTask.routeTask.length) {
            vm.objectRouteImg.routeId = vm.trTask.routeTask[0].routeId
        } else if (vm.trTask.sectionTask.length) {
            vm.objectRouteImg.routeId = vm.trTask.sectionTask[0].routeId
        }

        vm.objectRouteImg.map = locations.map

        function progresstransition() {
            /* $state.transitionTo($state.$current, {
                page: vm.progresspage,
                sort: vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                search: $scope.search
            }); */
            if ($scope.tab == 1) {
                callLogsPagination("PROGRESS")
            }
            if ($scope.tab == 5) {
                callLogsPagination("OBSTACLE")
            }
        }

        function obstacletransition() {
            $state.transitionTo($state.$current, {
                page: vm.obstaclepage,
                sort: vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                search: $scope.search
            });
        }


        vm.logComment = function (id, str) {
            vm.isShowComment = true

            vm.objectComments = {}
            vm.objectComments.entityId = id
            vm.objectComments.entityName = str

            $scope.tab = 2
        }

        function callRouteImage(arr, map) {
            if(arr.length){
                arr.forEach(function (each) {
                    if (each.imageId) {
                        var iconImage = "content/images/routePointImage.png"
                        var markerImage = new google.maps.Marker({
                            position: { lat: each.fromRoutePointLat, lng: each.fromRoutePointLng },
                            map: map,
                            icon: {
                                scaledSize: new google.maps.Size(24, 24),
                                url: iconImage,
                                anchor: new google.maps.Point(
                                    24 / 2 + 25,
                                    24 / 2
                                ),
                                labelOrigin: new google.maps.Point(25, 22),
                            },
                        });
    
                        google.maps.event.addListener(
                            markerImage,
                            "click",
                            (function () {
                                if (each && each.id) {
                                    vm.nameImages = []
                                    //scope.$apply(); 
                                    imageRoutePoint.getUserTaskLogImages(each.id, function (item) {
                                        if (item.length >= 2) {
                                            item.forEach(function (logs) {
                                                logs.rID = each.id
                                                vm.nameImages.push(logs)
                                            })
                                            $("#modalAttachmentLogImage").show();
                                        } else {
                                            item.map(function (media) {
                                                vm.newTabDisplayLogs(each.id, media.imageId, media.imageType)
                                            })
                                        }
                                        //$scope.$apply()
                                    })
                                }
                            })
                        );
                    }
                    //logImageMarkers.push(markerImage);
                })
            }


        }

        vm.newTabDisplayLogs = function (id, imgid, str) {
            var request = new XMLHttpRequest()
            request.open(
                "GET",
                'api/rollout/user-task-log-images/' + id + '/' + imgid,
                true
            );
            request.setRequestHeader(
                "Authorization",
                "Bearer " + $localStorage.authenticationToken
            );
            request.responseType = "blob";
            request.onload = response;
            function response(e) {
                if (this.status === 200) {
                    var urlCreator = window.URL || window.webkitURL;
                    if (str == "MP4") {
                        var videoUrl = urlCreator.createObjectURL(this.response);
                        var video = document.createElement('video');
                        video.src = videoUrl;
                        video.controls = true;
                        video.autoplay = true;
                        video.style.maxHeight = '80vh';

                        var videoWindow = window.open("", "_blank");
                        videoWindow.document.body.appendChild(video);
                    } else {
                        var imageUrl = urlCreator.createObjectURL(this.response);
                        window.open(imageUrl, "_blank");
                    }
                } else {
                    alert("Error " + this.status)
                }
            }
            request.send();
        }

        function callLogsPagination(type) {
            vm.dailyLogsList = []
            vm.obstacleLogsList = []
            DailyLogByTaskIdWithTypePagination.get(
                {
                    id: entity.id,
                    type: type,
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: "lastModifiedDate,desc"
                },
                function Success(response) {
                    if (response.content.length) {
                        vm.totalItems = response.totalElements;
                        vm.queryCount = response.totalElements;

                        if (type == 'PROGRESS') {
                            vm.dailyLogsList = response.content

                           /*  vm.dailyLogsList.forEach(function(item){
                                item.isImageAttached = true
                            }) */
                            callRouteImage(vm.dailyLogsList, locations.map)
                        } else if (type == 'OBSTACLE') {
                           
                            vm.obstacleLogsList = response.content
                            callRouteImage(vm.obstacleLogsList, locations.map)
                        }

                        if($scope.tab == 6){
                            showMapData(type)
                        }

                    }
                }
                ,
                function Error(error) {
                    AlertService.error(error.data.message);
                }
            );
        }
    }
})();